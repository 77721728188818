import React, { createRef, useState, useEffect } from 'react';
import readXlsxFile from 'read-excel-file';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import { TreeTable } from 'components/TreeTable';
import { genColumns as excelColumnNamesGenerator } from 'excel-like-column-name-generator';
// import parse from 'paste-from-excel';
import _ from 'lodash';
import {
  makeStyles,
  // TableContainer,
  // Table,
  // TableRow,
  // TableCell,
  // TableHead,
  Grid,
  Button,
} from '@material-ui/core';
import {
  CommonDialog,
  FormField2 as Field,
} from 'components';
import api from 'utils/api';
import {
  b64toBlob,
  blobToFileDownload,
} from 'utils/helpers';

const useStyles = makeStyles({
  root: {},
  footerWrap: {
    marginTop: 40,
  },
  viewWrap: {
    // height: '600px',
    // overflow: 'auto',
  }
})

const AUTO_MAP = {
  sam: 'estimate',
  sd: 'part',
  sk: 'chapter',
  psk: 'subchapter',
  elg: 'group',
  el: 'element',
  ikn: 'rate',
  ds: 'resource-work',
  med: 'resource-material',
  mech: 'resource-mechanism',
  ir: 'resource-equipment',
};

const VALID_ROW_TYPES = [
  'estimate',
  'part',
  'chapter',
  'subchapter',
  'group',
  'element',
  'rate',
  'resource',
  'resource-work',
  'resource-material',
  'resource-mechanism',
  'resource-equipment'
];

const RatesImportModal = ({ version, objectId, itemId, onClose, topLevelType = 'estimate' }) => {
  const classes = useStyles();
  const fileRef = createRef();
  const [file, setFile] = useState();
  const [record, setRecord] = useState({ columnNames: 'first-row' });
  const [headers, setHeaders] = useState();
  const [firstRow, setFirstRow] = useState();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState();
  const [allSelected, setAllSelected] = useState(false);
  const [allLocked, setAllLocked] = useState(false);
  const [fillColumnData, setFillColumnData] = useState(false);
  const { t } = useTranslation();
  // const { projectId, objectId, itemId } = useParams();

  useEffect(() => {
    if (!file) return;

    setHeaders(null);

    readXlsxFile(file).then((excelRows) => {
      if (!excelRows.length) return window.alert(t('Nėra duomenų'));

      const row = excelRows[0].map((value, idx) => ({ value: idx.toString(), label: value }));
      setFirstRow(row);
      setHeaders(row);
      setRows(excelRows);
    });
  }, [file]);

  useEffect(() => {
    const availableRows = hasFirstRow ? _.tail(result) : result;
    const isAllSelected = availableRows?.filter(r => r.selected)?.length === availableRows?.length;
    const isAllLocked = availableRows?.filter(r => r.locked)?.length === availableRows?.length;

    setAllSelected(isAllSelected);
    setAllLocked(isAllLocked);
  }, [result, , rows]);

  const toggleSelectAll = (checked) => {
    setResult(result?.map(item => ({ ...item, selected: checked })));
  };

  const toggleLockAll = (checked) => {
    setResult(result?.map(item => ({ ...item, locked: checked })));
  };

  const handleClose = (hadChanges = false) => {
    onClose(hadChanges);
  };

  const onFileChange = e => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleRowfieldChange = (rowIdx, field, value) => {
    const newResult = [...result];
    newResult[rowIdx][field] = value;

    setResult(newResult);
  }

  const handleMappingChange = ({ name, value }) => {
    const newRecord = { ...record, [name]: value };

    if (value === '') {
      delete newRecord[name];
    } else {
      if (name === 'columnNames' && value === 'first-row') {
        setHeaders(firstRow);
      } else if (name === 'columnNames' && value === 'none') {
        const row = excelColumnNamesGenerator(firstRow.length).map((value, idx) => ({ value: idx.toString(), label: value }))
        setHeaders(row);
      }
    }

    setRecord(newRecord);

    setResult(rows.map((rowValues, idx) => {
      let type = newRecord.type ? rowValues[newRecord.type] : null;
      // Set as selected on first load
      let selected = !result ? true : !!result?.[idx]?.selected;
      let locked = !result ? false : !!result?.[idx]?.locked;

      if (name === 'type') {
        // Attempt to auto select values
        type = AUTO_MAP[rowValues[value]?.toLowerCase()] || type;
        if (AUTO_MAP[rowValues[value]?.toLowerCase()]) {
          selected = true;
          locked = true;
        } else selected = false;
      } else {
        type = result?.[idx]?.type || type;
      }

      const item = {
        key: idx,
        selected,
        type,
        locked,
        ...columnsRaw.reduce((current, column) => {

          return { ...current, [column.key]: rowValues[newRecord[column.key]] }
        }, {}),
      };

      return item;
    }));
  }

  const correctResources = (items) => {
    let payload = items.map(item => {
      if (item.type.includes('resource')) {
        item.resourceType = item.type.split("-")[1];
        item.type = 'resource';
      }
      return item;
    });

    return payload;
  }

  const getExcelVersion = (items) => {
    let version = '';
    items.map(item => {
      if (item.modifiers != null) {
        let modifiers = item.modifiers.split(";");

        modifiers.map(mod => {
          if (mod.toLowerCase().includes('kainos')) {
            let versionCode = mod.match(/\d/g).join("");

            version = versionCode;
            return version;
          }
        });
      }
    })

    return version;
  }

  const applyModifiers = (items) => {
    let payload = items.map(item => {
      if (item.modifiers != null) {
        let modifiers = item.modifiers.split(";");
        let updatedSettings = [];
        modifiers.map(mod => {
          if (!mod.toLowerCase().includes('kainos')) {
            const coef = mod.split("=");
            if (['k1', 'k2', 'k3', 'k4'].includes(coef[0].toLowerCase())) {
              updatedSettings.push({ code: coef[0].toLowerCase(), value: coef[1] });
            } else {
              // * 10 * 10 instead of * 100 so javascript doesn't return 15 numbers after decimal
              updatedSettings.push({ code: coef[0].toLowerCase(), unit: 'percent', value: coef[1] * 10 * 10 - 100 });
            }
          }
        })

        if (updatedSettings.length) item.updatedSettings = updatedSettings;
      }
      return item;
    });

    return payload;
  }


  const emptyTypeRowCheck = (items) => {
    let payload = items.filter(item => item.selected && item.key != 0 &&
      !VALID_ROW_TYPES.includes(item.type)
    );

    return !!payload.length;
  }

  const handleSubmit = async () => {
    const hasFirstRow = record?.columnNames === 'first-row';
    const basePayload = (hasFirstRow ? _.tail(result) : result).filter(r => r.selected);
    const emptyTypeRows = emptyTypeRowCheck(basePayload);

    if (emptyTypeRows) {
      window.alert(t('Pažymėtos eilutės neturinčios tipo'));
      return;
    }

    const excelVersion = getExcelVersion(basePayload);
    if (excelVersion && version.code != excelVersion) {
      if (!window.confirm(t('Skaičiavimo objekto ir įkeliamų objektų versija nesutampa. Tęsti kėlimą skaičiavimo objekto versija?'))) {
        return;
      }
    }

    let payload = correctResources(basePayload);
    payload = applyModifiers(payload);

    if (!payload.length) {
      window.alert(t('Nepažymėta nei viena eilutė'));
      return;
    }

    setLoading(true);

    try {
      let query = '';
      if (fillColumnData) query = query.concat('?importEmpty=1');

      await api.post(`/estimates/${objectId}/items/${itemId}/import-rates${query}`, payload);

      handleClose(true);
    } catch (err) {
      console.log('Import failed', err);
      window.alert('Nepavyko įkelti');
      handleClose();
    }

    setLoading(false);
  };

  const handleSelectedRowExcelDownload = async () => {
    const hasFirstRow = record?.columnNames === 'first-row';
    const selectedRowIndexesFromExcel = [];
    (hasFirstRow ? _.tail(result) : result).filter((r, idx) => r.selected ? selectedRowIndexesFromExcel.push(idx) : null);

    const TYPES_MAP = {
      estimate: 'sam',
      part: 'sd',
      chapter: 'sk',
      subchapter: 'psk',
      group: 'elg',
      element: 'el',
      rate: 'ikn',
    };
    const RESOURCE_TYPES_MAP = {
      work: 'ds',
      material: 'med',
      mechanism: 'mech',
      equipment: 'ir',
    }

    const selectedRows = _.map(hasFirstRow ? _.tail(rows) : rows, (row, index) => {
      const idx = hasFirstRow ? index + 1 : index;

      if (!selectedRowIndexesFromExcel.includes(index)) {
        return row
      } else {
        const rowType = _.get(result[idx], 'type');
        let typeCode;

        if (rowType != null && rowType.includes('resource')) {
          const resourceType = rowType.split("-")[1];
          typeCode = RESOURCE_TYPES_MAP[resourceType];
        } else {
          if (TYPES_MAP[rowType.toLowerCase()]) {
            typeCode = TYPES_MAP[rowType.toLowerCase()];
          } else typeCode = null;
        }

        return [...rows[idx], typeCode];
      }
    });

    setLoading(true);

    try {
      const data = { headers: hasFirstRow ? [...headers, { value: headers.length.toString(), label: t('Tipas').toLowerCase() }] : null, rows: selectedRows };
      const response = await api.post(`/estimates/${objectId}/items/${itemId}/generate-rates-excel`, data);

      const fileName = file.name.substring(0, file.name.lastIndexOf(".")) + "_BG" + file.name.substring(file.name.lastIndexOf("."));
      downloadFile(fileName, response.data)
    } catch (err) {
      console.log('Download failed', err);
      window.alert('Nepavyko atsisiųsti');
    }

    setLoading(false);
  };

  const downloadFile = (name, base64Data) => {
    const blob = b64toBlob(base64Data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');

    blobToFileDownload(name, blob);
  }

  const handleMassTypeChange = (value) => {
    const newResult = [...result];
    newResult.forEach(r => !r.locked ? r.type = value : null);

    setResult(newResult);
  }

  const handleGetRowTypes = async () => {
    const newResult = [...result];
    const codes = [];

    _.map(newResult, row => {
      if (row.selected && row.code) codes.push(row.code);
    });

    const { data } = await api.post(`/estimates/${objectId}/get-row-types`, { codes });

    _.map(newResult, row => {
      if (row.selected && row.code) {
        const item = _.find(data, o => {
          if (o && row.code) return o.code.toLowerCase() == row.code.toString().toLowerCase();
        });

        if (item && item.type) {
          if (item.type != 'rate') {
            row.type = item.type;
            row.resourceType = item.type.split('-')[1];
          }
          if (item.type == 'rate') row.type = item.type;
          row.locked = true;
        }
      }
    });

    setResult(newResult);
  }

  const renderSelect = ({ name, value, onChange, options, style, disabled }) => (
    <select
      style={style || { width: '100%' }}
      onChange={onChange}
      value={value}
      name={name}
      disabled={disabled}
    >
      <option value="" />
      {options?.map((field) => (
        <option key={field.value} value={field.value}>{field.label}</option>
      ))}
    </select>
  )

  const rowTypes = [
    { key: 'SAM', value: 'estimate', label: t('Sąmata'), allowedFor: ['estimate'] },
    { key: 'SD', value: 'part', label: t('Sąmatos dalis'), allowedFor: ['estimate'] },
    { key: 'SK', value: 'chapter', label: t('Skyrius'), allowedFor: ['estimate', 'part'] },
    { key: 'PSK', value: 'subchapter', label: t('Poskyris'), allowedFor: ['estimate', 'part', 'chapter'] },
    { key: 'ELG', value: 'group', label: t('El. grupė'), allowedFor: ['estimate', 'part', 'chapter', 'subchapter'] },
    { key: 'EL', value: 'element', label: t('Elementas'), allowedFor: ['estimate', 'part', 'chapter', 'subchapter', 'group'] },
    { key: 'IKN', value: 'rate', label: t('Įkainis'), allowedFor: ['estimate', 'part', 'chapter', 'subchapter', 'group', 'element'] },
    { key: 'DS', value: 'resource-work', resourceType: 'work', label: t('Darbas'), allowedFor: ['rate', 'estimate', 'part', 'chapter', 'subchapter', 'group', 'element', 'resource-work'] },
    { key: 'MED', value: 'resource-material', resourceType: 'material', label: t('Medžiaga'), allowedFor: ['rate', 'estimate', 'part', 'chapter', 'subchapter', 'group', 'element'] },
    { key: 'MECH', value: 'resource-mechanism', resourceType: 'mechanism', label: t('Mechanizmas'), allowedFor: ['rate', 'estimate', 'part', 'chapter', 'subchapter', 'group', 'element'] },
    { key: 'IR', value: 'resource-equipment', resourceType: 'equipment', label: t('Įrenginys'), allowedFor: ['rate', 'estimate', 'part', 'chapter', 'subchapter', 'group', 'element'] },
  ].filter(item => item.allowedFor.includes(topLevelType));

  const columnsRaw = [
    { key: 'code', label: t('Kodas') },
    { key: 'title', label: t('Pavadinimas'), width: 300 },
    { key: 'unit', label: t('Mat. vnt.'), width: 80 },
    { key: 'norm', label: t('Norma'), width: 80 },
    { key: 'quantity', label: t('Kiekis') },
    { key: 'price', label: t('Kaina') },
    { key: 'amount', label: t('Suma') },
    { key: 'modifiers', label: t('Papildoma informacija'), width: 140 },
    { key: 'workComposition', label: t('Darbų sudėtis') },
  ];

  const columns = [
    {
      dataIndex: 'selected',
      key: 'selected',
      width: 40,
      title: (
        <div style={{ textAlign: 'center' }}>
          <input
            type="checkbox"
            checked={allSelected}
            style={{ verticalAlign: 'middle' }}
            onChange={(e) => toggleSelectAll(e.currentTarget.checked)}
          />
        </div>
      ),
      render: (value, item) => {

        return (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={value}
              style={{ verticalAlign: 'middle' }}
              onChange={(e) => handleRowfieldChange(item.key, 'selected', e.currentTarget.checked)}
            />
          </div>
        )
      }
    },
    ...columnsRaw.map((col) => ({
      title: (
        <>
          {col.label}
          {renderSelect({
            name: col.key,
            value: record?.[col.key],
            onChange: (e) => handleMappingChange({ name: col.key, value: e.target.value }),
            options: headers,
          })}
        </>
      ),
      dataIndex: col.key,
      key: col.key,
      width: col.width || 100,
      render: (text) => <span className="ellipsis" title={text}>{text}</span>
    })),
    {
      title: (
        <>
          {t('Tipas')}
          {renderSelect({
            name: 'type',
            value: record?.type,
            onChange: (e) => handleMappingChange({ name: 'type', value: e.target.value }),
            options: headers || [],
          })}
        </>
      ),
      dataIndex: 'type',
      key: 'type',
      width: 80,
      render: (text, item) => {

        return renderSelect({
          name: 'type',
          value: item?.type,
          onChange: (e) => handleRowfieldChange(item.key, 'type', e.target.value),
          options: rowTypes,
        })
      }
    },
    {
      dataIndex: 'locked',
      key: 'locked',
      width: 55,
      title: (
        <div style={{ textAlign: 'center' }}>
          {`${t('Išlaikyti tipą')} `}
          <input
            type="checkbox"
            checked={allLocked}
            style={{ verticalAlign: 'middle' }}
            onChange={(e) => toggleLockAll(e.currentTarget.checked)}
          />
        </div>
      ),
      render: (value, item) => {

        return (
          <div style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              checked={value}
              style={{ verticalAlign: 'middle' }}
              onChange={(e) => handleRowfieldChange(item.key, 'locked', e.currentTarget.checked)}
            />
          </div>
        )
      }
    },
  ];

  const hasMappings = !!Object.keys(_.pick(record, ['code', 'title'])).length;
  const hasFirstRow = record?.columnNames === 'first-row';

  const data = hasMappings ? (hasFirstRow ? _.tail(result) : result) : [];

  return (
    <CommonDialog
      visible
      onClose={handleClose}
      maxWidth={!file ? 'sm' : 'xl'}
      style={{ position: 'relative' }}
      title="Įkainių įkėlimas iš Excel"
      loading={loading}
    >
      <div className={classes.viewWrap}>
        <input
          type="file"
          ref={fileRef}
          onChange={onFileChange}
          accept=".xlsx"
          style={{ display: 'none' }}
        />

        {!file && (
          <div style={{ textAlign: 'center' }}>
            <Button color="primary" variant="contained" onClick={() => fileRef.current.click()}>
              {t('Pasirinkti Excel dokumentą')}
            </Button>
          </div>
        )}

        {!!headers && (
          <>
            <div style={{ margin: '0px auto' }}>
              <Grid
                container
                spacing={2}
              >
                <Grid item xs={4}>
                  <Field
                    label={t('Laukų pavadinimai')}
                    type="select"
                    options={[
                      { value: 'first-row', label: t('Pirmoje eilutėje') },
                      { value: 'none', label: t('Nėra (įvardinti raidėmis A...Z)') },
                    ]}
                    name="columnNames"
                    onChange={handleMappingChange}
                    value={record?.columnNames}
                  />
                </Grid>
              </Grid>

              <h4 style={{ marginTop: 10, marginBottom: 0 }}>{t('Excel stulpelių reikšmės')}</h4>

              <div className="rates-import-table">
                <TreeTable
                  onCellChange={() => { }}
                  columns={columns}
                  data={data}
                  locale={{ emptyText: <div style={{ padding: 30 }}>{t('Nėra duomenų')}</div> }}
                  scroll={{ y: 400 }}
                  footer={() => (
                    <div style={{ textAlign: 'right', display: 'inline-flex', justifyContent: 'flex-end', width: '100%' }}>
                      <div style={{ textAlign: 'center', paddingRight: 12 }}>
                        <input
                          type="checkbox"
                          checked={fillColumnData}
                          style={{ verticalAlign: 'middle', marginRight: 6 }}
                          onChange={(e) => setFillColumnData(!fillColumnData)}
                        />
                        {`${t('Trūkstamus duomenis įkelti iš duomenų bazės')} `}
                      </div>

                      <div style={{ display: 'inline-block', marginRight: 5, fontWeight: '500' }}>
                        {t('Priskirti tipą visoms eilutėms')}:
                      </div>
                      <div style={{ display: 'inline-block', marginRight: 7 }}>
                        {renderSelect({
                          // name: col.key,
                          value: '',
                          onChange: (e) => handleMassTypeChange(e.target.value),
                          options: rowTypes,
                          disabled: !result,
                        })}
                      </div>
                    </div>
                  )}
                />
              </div>

              <div style={{ textAlign: 'right', marginTop: 20 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleGetRowTypes}
                  disabled={!record?.code || loading}
                  style={{ marginRight: 10 }}
                >
                  {t('Pritaikyti eilučių tipus')}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSelectedRowExcelDownload}
                  disabled={!record?.code || loading}
                  style={{ marginRight: 10 }}
                >
                  {t('Atsisiųsti')}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!record?.code || loading}
                >
                  {t('Įkelti')}
                </Button>
              </div>
            </div>
          </>
        )}

      </div>

    </CommonDialog>
  )
}

export default RatesImportModal;