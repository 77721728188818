import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import api from 'utils/api';
import { toast } from 'utils/helpers';
import { DataTable } from 'components';

const ProjectRecordsList = ({ projectId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchRecords = async (projectId) => {
    setLoading(true);

    try {
      const { data } = await api.get(`/projects/${projectId}/latest`);

      setRecords(data);
    } catch (err) {
      console.error(err);
      toast(err.message, 'error');
    }

    setLoading(false);
  };

  const prepareTableColumns = () => {
    const columns = [
      'ID',
      'LINK',
      t('Pavadinimas'),
      t('Tipas'),
      t('Kainyno versija'),
      t('Suma, EUR be PVM'),
      t('Suma, EUR su PVM'),
      t('Sukūrė'),
      t('Sukūrimo data'),
      t('Atnaujino'),
      t('Atnaujinimo data'),
    ];

    return columns;
  }

  const prepareTableData = (records) => {
    const data = records.map(record => {
      let row = [];

      let url;

      if (record.type === 'estimateObject') {
        url = `/projects/${projectId}/objects/${record._id}`;
      } else if (record.type === 'ntk') {
        url = `/recovery-price/${record._id}`;
      } else if (record.type === 'estimateSummary') {
        url = `/projects/${projectId}/summaries/${record._id}`;
      }

      row.push(record._id);
      row.push(url);
      row.push(record.title || t('Nenurodytas'));
      row.push(record.typeTitle);
      row.push(record.version);
      row.push(<div style={{ textAlign: 'right' }}>{record.total}</div>);
      row.push(<div style={{ textAlign: 'right' }}>{record.grandTotal}</div>);
      row.push(record.owner);
      row.push(record.createdAt ? moment(record.createdAt).utc().format('YYYY-MM-DD') : null);
      row.push(record.updatedBy);
      row.push(record.updatedAt ? moment(record.updatedAt).utc().format('YYYY-MM-DD') : null);

      return row;
    });

    return data;
  };

  useEffect(() => {
    fetchRecords(projectId);
  }, []);

  const columns = prepareTableColumns();
  const data = prepareTableData(records);

  return (
    <>
      {!loading && (
        <DataTable
          columns={columns}
          data={data}
        />
      )}
    </>
  )
}

export default ProjectRecordsList;