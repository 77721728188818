import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';
import { Button } from '@material-ui/core';

import { CommonDialog, AutoComplete, FormField2 as Field } from 'components';

import * as objectService from 'services/estimateObjects';
import * as projectService from 'services/projects';

// const EstimateObjectImportModal = ({
//   onClose,
//   ...rest
// }) => {
//   const { t } = useTranslation();

//   const handleClose = () => {
//     !!onClose && onClose();
//   }

//   return (
//     <CommonDialog
//       visible
//       onClose={handleClose}
//       maxWidth="sm"
//       style={{ position: 'relative' }}
//       title={t('Skaičiavimo objekto paieška')}
//     >
//       ...
//     </CommonDialog>
//   );
// }

const EstimateObjectImportModal = (props) => {
  const { t } = useTranslation();
  const { onClose, onSelect, versionCode } = props;

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [objects, setObjects] = useState([]);
  const [selectedObject, setSelectedObject] = useState(null);
  const [matchVersion, setMatchVersion] = useState(false);

  useEffect(() => {
    getProjects();
  }, []);

  const handleClose = () => {
    !!onClose && onClose();
  }

  const getProjects = async () => {
    const projects = await projectService.list({ lite: true });
    setProjects(projects);

    if (props.projectId) {
      handleChange({ name: 'project', value: props.projectId })
    }
  }

  const getObjects = async (projectId) => {
    if (!projectId) return;

    const objects = await objectService.list({ projectId });
    setObjects(objects);
  }

  const handleChange = ({ name, value }) => {
    console.log('change', { name, value });

    if (name == 'project') {
      setSelectedObject(null);
      setSelectedProject(value);
      getObjects(value);
    }

    if (name == 'object') {
      setSelectedObject(value);
    }
  };

  const handleSelect = () => {
    if (!selectedObject) return;

    const shouldMatchVersion = !objectVersionMatches() && matchVersion;

    onSelect(selectedObject, shouldMatchVersion);
  }

  const objectVersionMatches = () => {
    return objects.find(object => object._id === selectedObject)?.version?.code === versionCode;
  }

  return (
    <CommonDialog
      visible
      onClose={handleClose}
      maxWidth="sm"
      style={{ position: 'relative' }}
      title={t('Skaičiavimo objekto paieška')}
      actions={[
        <Button
          color="default"
          variant="text"
          onClick={() => handleClose()}
        >
          {t("Atšaukti")}
        </Button>,
        <Button
          color="primary"
          variant="contained"
          disabled={!selectedObject || (!objectVersionMatches() && !matchVersion)}
          onClick={() => handleSelect()}
        >
          {t('Įkelti skaičiavimą')}
        </Button>
      ]}
    >
      <div>
        <AutoComplete
          name="project"
          label={t('Projektas')}
          options={projects.map((project) => ({
            key: project._id,
            value: project._id,
            title: project.code ? `${project.code} - ${project.title}` : project.title,
          }))}
          freeSolo={true}
          creatable={false}
          value={selectedProject}
          style={{ height: '32px', marginTop: '8px', marginBottom: '30px' }}
          onChange={(newValue) => handleChange({ name: 'project', value: newValue?.value })}
        />

        <AutoComplete
          name="object"
          label={t('Skaičiavimų objektas')}
          options={objects.map((object) => ({
            ...object,
            title: versionCode !== object?.version?.code
              ? `${object.title} (${t('kainynas')} ${object?.version?.version})`
              : object.title,
          }))}
          freeSolo={true}
          creatable={false}
          disabled={!selectedProject}
          value={selectedObject}
          style={{ height: '32px', marginTop: '8px', marginBottom: '10px' }}
          onChange={(newValue) => handleChange({ name: 'object', value: newValue?._id })}
        />

        {!objectVersionMatches() && !!selectedObject && (
          <Field
            label={t('Perskaičiuoti suvestinio skaičiavimo versijoje')}
            type="checkbox"
            name="matchVersion"
            onChange={(({ value }) => setMatchVersion(value))}
            value={matchVersion}
          />
        )}
      </div>
    </CommonDialog>
  )
}

export default EstimateObjectImportModal;