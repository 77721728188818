import ProjectsList from './components/ProjectsList';
import ProjectsForm from './components/ProjectsForm';
import ProjectsDashBoard from './components/ProjectsDashBoard';
import EstimateObjectForm from './components/EstimateObjectForm/EstimateObjectForm';
import EstimateObjectTransferForm from './components/EstimateObjectTransferForm/EstimateObjectTransferForm';
import ImportManager from './components/EstimateImport/ImportManager';
import ModelViewer from './components/ModelViewer/ModelViewer';
import EstimateSummary from './components/EstimateSummary/EstimateSummary';

export default [
  {
    key: 'list',
    component: ProjectsList,
    path: '/projects',
    public: false,
  },
  {
    key: 'list',
    component: ProjectsDashBoard,
    path: '/projects/:id/dashboard',
    public: false,
  },
  {
    key: 'estimate-object-edit',
    component: EstimateObjectForm,
    path: '/projects/:projectId/objects/create',
    public: false,
  },
  {
    key: 'estimate-object-transfer',
    component: EstimateObjectTransferForm,
    path: '/transfer/:transferId',
    public: false,
  },
  {
    key: 'estimate-object-create',
    component: EstimateObjectForm,
    path: '/projects/:projectId/objects/:objectId',
    public: false,
  },
  {
    key: 'estimate-object-import',
    component: ImportManager,
    path: '/projects/:projectId/objects/:objectId/import',
    public: false,
  },
  {
    key: 'estimate-summaryc-create',
    component: EstimateSummary,
    path: '/projects/:projectId/summaries/create',
    public: false,
  },
  {
    key: 'estimate-summaryc-edit',
    component: EstimateSummary,
    path: '/projects/:projectId/summaries/:summaryId',
    public: false,
  },
  // {
  //   key: 'list',
  //   component: ProjectModel,
  //   path: '/projects/model/:id',
  //   public: false,
  // },
  // {
  //   key: 'list',
  //   component: ProjectsDocuments,
  //   path: '/projects/documents/:id',
  //   public: false,
  // },
  // {
  //   key: 'list',
  //   component: ProjectMembers,
  //   path: '/projects/members/:id',
  //   public: false,
  // },
  // {
  //   key: 'list',
  //   component: ProjectCommunication,
  //   path: '/projects/communication/:id',
  //   public: false,
  // },
  // {
  //   key: 'list',
  //   component: ProjectHistory,
  //   path: '/projects/history/:id',
  //   public: false,
  // },
  {
    key: 'create',
    component: ProjectsForm,
    path: '/projects/create',
    public: false,
  },
  {
    key: 'edit',
    component: ProjectsForm,
    path: '/projects/:id',
    public: false,
  },
  {
    key: 'model-viewer',
    component: ModelViewer,
    path: '/projects/:projectId/model-viewer',
    public: false,
  },
];