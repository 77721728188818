import api from 'utils/api';
import { objectToQueryString } from 'utils/helpers';

const servicePath = '/estimate-summaries';

export const list = async (params) => {
  const query = objectToQueryString(params);
  const { data } = await api.get(`${servicePath}${query ? '?' + query : ''}`);

  return data;
}

export const show = async (id, showUndistributed = false) => {
  const { data } = await api.get(`${servicePath}/${id}?showUndistributed=${showUndistributed}`);

  return data;
}

export const create = async (details) => {
  const { data } = await api.post(servicePath, details);

  return data;
}

export const update = async (id, details) => {
  const { data } = await api.put(`${servicePath}/${id}`, details);

  return data;
}

export const remove = async (id) => {
  return await api.delete(`${servicePath}/${id}`);
}

export const getConfig = async () => {
  const { data } = await api.get(`${servicePath}/config`);

  return data;
}

export const recalculate = async (id) => {
  const { data } = await api.get(`${servicePath}/${id}/recalculate`);

  return data;
}