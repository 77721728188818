import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommonDialog,
  CommonPanel,
} from 'components';
import {
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
} from '@material-ui/core';
import { blobToFileDownload } from 'utils/helpers';
import api from 'utils/api';

const EstimateSummaryPrintModal = ({ onClose, summaryId, itemId, onPrint }) => {
  const { t } = useTranslation();

  const reports = [
    {
      title: `${t('Suvestinis statybos kainos apskaičiavimas')}`,
      type: 'summaryConstructionPrice',
      params: ['id',],
      values: [summaryId],
    },
  ];

  const handlePrint = async ({ type, params, values, fileType }) => {

    try {
      const payload = {
        type,
        params,
        values,
        fileType,
      };

      const { data } = await api.post(`/estimate-summaries/${summaryId}/print`, payload, { timeout: 1000 * 120 });
      const { fileName, contentType, content } = data;

      var bytes = new Uint8Array(content);
      const blob = new Blob([bytes], { type: contentType });

      blobToFileDownload(fileName, blob);
    } catch (err) {
      console.error(err);
      window.alert(t('Įvyko klaida'));
    }

    onClose();
  };

  return (
    <CommonDialog
      visible
      onClose={onClose}
      maxWidth="md"
      style={{ position: 'relative' }}
    // title={t('Spausdinimas')}
    >
      <div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '80%' }}>{t('Pavadinimas')}</TableCell>
              <TableCell colSpan={2} style={{ textAlign: 'center' }}>{t('Formatas')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map(({ title, type, ...rest }) => (
              <TableRow key={type}>
                <TableCell>{title}</TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Button variant="outlined" color="primary" onClick={() => handlePrint({ fileType: 'PDF', type, ...rest })}>
                    PDF
                  </Button>
                </TableCell>
                <TableCell style={{ textAlign: 'center' }}>
                  <Button variant="outlined" color="primary" onClick={() => handlePrint({ fileType: 'XLSX', type, ...rest })}>
                    XLSX
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </div>
    </CommonDialog>
  )
};

export default EstimateSummaryPrintModal;