import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

import styles from './styles';
import { getCurrentAccount } from 'utils/helpers';
import { history } from 'utils/history';
import { DataTable, DataTableEditAction as EditAction } from 'components';

import Projects from 'views/Projects';
import { getAll } from './_actions';

// Inject reducers
import { store } from 'utils/store';
import { projectsList } from './_reducers';
store.injectReducer('projectsList', projectsList);

class ProjectsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      searchText: null,
      selectedColumn: null
    };

    this.onSearchChange = _.debounce(this.onSearchChange, 500);
  }

  onSearchChange(page, pageSize, sortColumn, sortOrder, search, filters) {
    if (search?.length > 0) {
      this.setState({
        searchText: search
      });
    } else this.setState({ searchText: '' });
    this.handleTableChange(
      page,
      pageSize,
      sortColumn,
      sortOrder,
      search,
      filters
    );
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(getAll());
  }

  handleTableChange(page, pageSize, sortColumn, sortOrder, search, filters) {
    const { dispatch, projectId } = this.props;
    const filter = { page, pageSize, sortColumn, sortOrder, search, filters };
    if (projectId) filter.project = projectId;

    dispatch(getAll(filter));
  }

  prepareTableColumns() {
    const { t } = this.props;

    const columns = [
      'ID',
      'LINK',
      {
        name: t('Kodas'), value: 'code',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <b><Link to={value.value}>{value.code}</Link></b>
            );
          }
        }
      },
      { name: t('Priklauso kodui'), value: 'parent.code' },
      { name: t('Projekto pavadinimas'), value: 'title' },
      { name: t('Sukūrė'), value: 'owner.user.fullName' },
      { name: t('Sukūrimo data'), value: 'createdAt' },
      { name: t('Atnaujino'), value: 'updatedBy.user.fullName' },
      { name: t('Atnaujinimo data'), value: 'updatedAt' },
      {
        // name: 'actions',
        // label: 'Veiksmai',
        options: {
          filter: false,
          sort: false,
          searchable: false,
          print: false,
          download: false,
          viewColumns: false
        }
      }
    ];

    return columns;
  }

  prepareTableData(records) {
    const { t } = this.props;
    const data = _.map(records, record => {
      let row = [];

      row.push(record._id);
      row.push(`/projects/${record._id}/dashboard`);
      row.push({ value: `/projects/${record._id}/dashboard`, code: record.code || t('Nenurodytas') });
      row.push(record.parent ? record.parent.code : null);
      row.push(record.title);
      row.push(record.owner ? record.owner.user?.fullName : null);
      row.push(
        record.createdAt
          ? moment(record.createdAt)
            .utc()
            .format('YYYY-MM-DD')
          : null
      );
      row.push(record.updatedBy ? record.updatedBy.user?.fullName : null);
      row.push(
        record.updatedAt
          ? moment(record.updatedAt)
            .utc()
            .format('YYYY-MM-DD')
          : null
      );
      row.push(<EditAction url={`/projects/${record._id}`} />);

      return row;
    });

    return data;
  }

  createNew() {
    const account = getCurrentAccount();
    const { records, t } = this.props;

    if (account.isDefault && records.length) {
      window.alert(t('Pasiektas DEMO aplinkos projektų kiekio limitas'));
      return;
    }

    history.push('/projects/create');
  }

  render() {
    const { classes, records, isLoading, t } = this.props;

    const columns = this.prepareTableColumns();
    const data = this.prepareTableData(records);

    const tableOptions = {
      serverSide: true,
      count: this.props.totalRecords || 0,
      searchText: this.state.searchText,
      pagination: isLoading ? false : true,
      textLabels: {
        body: {
          noMatch: isLoading ? t('Įkeliama') : t('Įrašų nerasta')
        }
      },
      // TODO search needs crafting...
      // onSearchChange: (q) => this.handleTableChange(null, null, null, q),
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changeRowsPerPage':
          case 'changePage':
            this.handleTableChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortColumn,
              tableState.sortOrder,
              tableState.searchText,
              tableState.filters
            );
          case 'search':
            this.onSearchChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortColumn,
              tableState.sortOrder,
              tableState.searchText,
              tableState.filters
            );
            break;
          case 'sort':
            const sortColumn = tableState.activeColumn;
            let order = tableState.sortOrder ? tableState.sortOrder : -1;

            if (this.state.selectedColumn === sortColumn) {
              order = tableState.sortOrder * -1;
              tableState.sortOrder = order;
            }

            this.setState({ selectedColumn: sortColumn });
            tableState.sortOrder = order;
            tableState.sortColumn = columns[sortColumn].value;
            this.handleTableChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortColumn,
              tableState.sortOrder,
              tableState.searchText,
              tableState.filters
            );
            break;
          case 'filterChange':
            const matches = [];
            _.map(tableState.filterList, (filterArray, index) => {
              if (filterArray.length > 0) {
                let matchCond = {};
                let col = columns[index].value;
                matchCond[col] = filterArray[0];

                matches.push({ matchCond });
              }
            });
            if (matches.length < 1) {
              tableState.filters = undefined;
            } else {
              matches.push(tableState.filters);
              tableState.filters = matches[0].matchCond;
            }
            this.handleTableChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortColumn,
              tableState.sortOrder,
              tableState.searchText,
              JSON.stringify(tableState.filters)
            );
            break;
          case 'resetFilters':
            tableState.filters = undefined;
            this.handleTableChange(
              tableState.page,
              tableState.rowsPerPage,
              tableState.sortColumn,
              tableState.sortOrder,
              tableState.searchText,
              JSON.stringify(tableState.filters)
            );
            break;
          default:
            // Not handling other actions
            break;
        }
      }
    };

    return (
      <Projects
        isLoading={isLoading}
        title={t('Projektai')}
        breadcrumbs={[{ title: t('Projektai') }]}>
        <div className={classes.content}>
          <DataTable
            columns={columns}
            data={!isLoading ? data : []}
            tableHeight={window.innerHeight > 900 ? '700px' : '490px'}
            // createUrl="/projects/create"
            createHandler={this.createNew.bind(this)}
            options={tableOptions}
          // onDelete={(idx) => dispatch(deleteOne(companies[idx]._id))}
          // onDeleteBatch={(indexes) => dispatch(deleteBatch(_.map(indexes, (idx) => companies[idx]._id)))}
          />
        </div>
      </Projects>
    );
  }
}

const mapStateToProps = ({ projectsList, dispatch }) => {
  return {
    ...projectsList,
    dispatch
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation()
)(ProjectsList);
