import React, { Component } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  withStyles,
  Grid,
  Typography,
} from '@material-ui/core';

// import { Dashboard as DashboardLayout } from 'layouts';
import Projects from 'views/Projects';

import { FeaturedItem } from 'components';
import { getProject } from './_actions';
import { history } from 'utils/history';
import styles from './styles';
import { checkAccess } from 'services/accounts';
import { canAccess } from 'utils/helpers';

// Inject reducers
import { store } from 'utils/store';
import { projectsDashboard } from './_reducers';
import RecoveryPriceTable from 'components/RecoveryPriceTable';
import EstimateObjectList from '../EstimateObjectList/EstimateObjectList';
import ProjectRecordsList from '../ProjectRecordsList/ProjectRecordsList';
store.injectReducer('projectsDashboard', projectsDashboard);


class ProjectsDashboard extends Component {

  componentDidMount() {
    const { match, dispatch } = this.props;
    
    if (match.params.id) {
      dispatch(getProject(match.params.id));
    }
  }

  async checkDemoAccess(url) {
    try {
      await checkAccess();

      history.push(url);
    } catch(err) {
      window.alert(err.response.data.message);
    }
  }

  renderServiceButtons() {
    const { classes, project, t } = this.props;
    
    if (!project) {
      return;
    }

    return (
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <FeaturedItem
            className={classes.item}
            subtitle={t('Paslauga')}
            title={t('Atkuriamoji vertė')}
            footerLink={`/recovery-price/create?project=${project.id}`}
            footerClickHandler={() => this.checkDemoAccess(`/recovery-price/create?project=${project.id}`)}
            footerText={t('Naujas skaičiavimas')}
          />
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <FeaturedItem
            className={classes.item}
            subtitle={t('Paslauga')}
            title={t('Sąmatos')}
            footerLink={`/projects/${project.id}/objects/create${!canAccess('estimates.layout') ? '?trial=1' : ''}`}
            footerText={t('Naujas skaičiavimas')}
            footerClickHandler={() => this.checkDemoAccess(`/projects/${project.id}/objects/create${!canAccess('estimates.layout') ? '?trial=1' : ''}`)}
          />
        </Grid>

        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <FeaturedItem
            className={classes.item}
            subtitle={t('Paslauga')}
            title={t('Suvestiniai skaičiavimai')}
            footerLink={`/projects/${project.id}/summaries/create${!canAccess('estimates.layout') ? '?trial=1' : ''}`}
            footerText={t('Naujas skaičiavimas')}
            footerClickHandler={() => this.checkDemoAccess(`/projects/${project.id}/summaries/create${!canAccess('estimates.layout') ? '?trial=1' : ''}`)}
          />
        </Grid>

        {/* <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <FeaturedItem
            className={classes.item}
            subtitle={t('Paslauga')}
            title={t('IFC peržiūra')}
            footerLink={`/projects/${project.id}/model-viewer${!canAccess('estimates.layout') ? '?trial=1' : ''}`}
            footerText={t('Atidaryti įrankį')}
            footerClickHandler={() => this.checkDemoAccess(`/projects/${project.id}/model-viewer${!canAccess('estimates.layout') ? '?trial=1' : ''}`)}
          />
        </Grid> */}
      </Grid>
    );
  }

  render() {
    const { classes, isLoading, project, t } = this.props;

    const title = (
      <span>
        Projektas
        {project ? (
          <React.Fragment>
            <Typography variant="h5">{project.title}</Typography>
          </React.Fragment>
        ) : null}
      </span>
    );

    return (
      <Projects
        title={title}
        // menu={menu}
        // menuTitle={menuTitle}
        isContentLoading={isLoading}
        breadcrumbs={[
          { title: t('Projektai'), to: '/projects' },
          { title: project ? project.title : '' },
          { title: t('Apžvalga') },
        ]}
      >

        {project && !isLoading ? (
          <div className={classes.root}>
            {this.renderServiceButtons()}

            <div className={classes.verticalBlock}>
              <ProjectRecordsList projectId={project.id} />
              {/* <EstimateObjectList projectId={project.id} /> */}
            </div>

            {/* <div className={classes.verticalBlock}>
              <RecoveryPriceTable projectId={project.id} />
            </div> */}
          </div>
        ) : null}

      </Projects>
    );
  }
}

const mapStateToProps = ({ projectsDashboard, dispatch }) => {
  return { 
    ...projectsDashboard,
    dispatch,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
  withTranslation(),
)(ProjectsDashboard);
