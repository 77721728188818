import React, { useState, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import { ClickAwayListener } from '@material-ui/core';

const TreeEditableCellV2 = (props) => {
  const {
    value: initialValue,
    name,
    item,
    type,
    onChange,
    className = '',
    inputProps = {},
    style = {},
    onSetCurrentlyEditable = () => { },
    currentlyEditable = null,
    readonly,
    renderDisplay,
  } = props;
  const [value, setValue] = useState(initialValue);
  const handleClickOutside = (ignoreChange = false) => {
    setIsEditing(false);
    onSetCurrentlyEditable(null);
    if (initialValue !== value && !ignoreChange) onChange({ name, value, item });
  };
  const inputRef = useRef(null);
  const currentBeingEdited = currentlyEditable?.id === item.id && currentlyEditable?.name === name;
  const [isEditing, setIsEditing] = useState(currentBeingEdited);


  useEffect(() => {
    if (!isEditing) return;

    inputRef.current?.focus();
    !currentBeingEdited && onSetCurrentlyEditable({ id: item.id, name });
  }, [isEditing]);

  const throttledOnChange = useCallback(
    _.debounce((params) => onChange(params), 2000),
    [],
  );

  const handleOnChange = (newValue) => {
    setValue(newValue);

    // throttledOnChange({ name, value: newValue, item });
  };

  const handleOnKeyDown = (e) => {
    if (e.shiftKey) e.stopPropagation();  // Prevent SHIFT key from losing focus of input

    if (e.keyCode === 13) handleClickOutside();

    if (e.key === 'Escape') {
      setValue(initialValue);
      handleClickOutside(true);
    }
  };

  let input;
  switch (type) {

    case 'textarea':
      input = (
        <div className="popout-input">
          <textarea ref={inputRef} {...inputProps} onKeyDown={handleOnKeyDown} onChange={(e) => handleOnChange(e.target.value)} value={value} />
        </div>
      );
      break;

    default:
      input = <input ref={inputRef} {...inputProps} onKeyDown={handleOnKeyDown} onChange={(e) => handleOnChange(e.target.value)} value={value} />;
  }

  let isReadonly = false;
  if (typeof readonly !== 'function') {
    isReadonly = readonly;
  } else {
    isReadonly = readonly(item);
  }

  return (
    <ClickAwayListener onClickAway={() => handleClickOutside()}>
      <div onClick={() => !isReadonly ? setIsEditing(true) : undefined} style={style} className={`tree-editable-cell ${className} ${isEditing ? 'is-editing' : ''} ${isReadonly ? 'readonly' : ''}`} title={value}>
        {isEditing ? input : ((value === null || value === undefined || value === '') ? <>&nbsp;</> : (renderDisplay ? renderDisplay({ name, value, item }) : value))}
      </div>
    </ClickAwayListener>
  );
}

export default TreeEditableCellV2;