import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  IconButton,
  Button,
  Menu,
  MenuItem,
} from '@material-ui/core';
import {
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import NestedMenuItem from 'material-ui-nested-menu-item';

const useStyles = makeStyles(theme => ({
  root: {},
  itemMenu: {
    '& div': {
      minWidth: '120px',
    },
    '& svg': {
      right: '4px',
      position: 'absolute',
    },
  },
}));

const TableActionMenu = ({ options = [], className = '', style = {} }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isDisabled = !options.length;

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        disableRipple
        style={{ opacity: isDisabled ? 0.5 : 1, background: '#0967da', borderRadius: '5px', color: '#ffffff', ...style }}
        className={className}
        disabled={isDisabled}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        variant="menu"
        className={classes.itemMenu}
        // anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {options.map(({ title, handler, disabled, childs = [] }) => !childs.length ? (
          <MenuItem
            key={title}
            onClick={handler}
            disabled={disabled}
          >
            {title}
          </MenuItem>
        ) : (
          <NestedMenuItem
            key={title}
            disabled={disabled}
            label={title}
            parentMenuOpen={!!open}
          >
            {childs.map((child) => (
              <MenuItem
                key={`child-${child.title}`}
                onClick={child.handler}
                disabled={child.disabled}
              >
                <span title={child.title}>{_.truncate(child.title, { length: 30 })}</span>
              </MenuItem>
            ))}
          </NestedMenuItem>
        ))}
      </Menu>
    </>
  );
}

export default TableActionMenu;