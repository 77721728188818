import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Typography,
  Button,
  Tabs,
  Tab,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  FormField2 as Field,
} from 'components';
import api from 'utils/api';
import { highlightText, twoOrMoreDecimals } from 'utils/helpers';

const TYPES = [
  { value: 'work', label: 'Darbas' },
  { value: 'mechanism', label: 'Mechanizmas' },
  { value: 'material', label: 'Medžiaga' },
];

const TABS = {
  SEARCH: 0,
  SELECTED: 1,
};

const useStyles = makeStyles({
  root: {},
  selectableRow: {

    '&:hover': {
      background: '#cccccc',
      cursor: 'pointer',
    }
  },
  selectedRow: {
    background: '#cccccc',
  },
  fieldsLabel: {
    display: 'inline-block',
    margin: '0px 10px',
  },
  compareWrap: {
    border: '1px solid #eee',
    borderRadius: '5px',
    marginTop: '10px',
  },
})

const ResourceSearch = ({ onSelect, lockedType, version, originalItem }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [text, setText] = useState(originalItem?.title?.replace(/"/g, ''));
  const [type, setType] = useState(lockedType || TYPES[2].value);
  const [records, setRecords] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [activeTab, setActiveTab] = useState(TABS.SEARCH);
  const isMultiple = !!originalItem?.multiple;

  const search = async (params) => {
    if (!params.search || records) {
      setRecords(null);
      return;
    }

    if (!params.search || params.search?.length < 1) return;

    setLoading(true);

    try {
      const { data } = await api.post('/services/norms/resources/search', params);

      setRecords(data);
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  }

  const throttledSearch = useCallback(
    _.debounce((params) => search(params), 1000),
    [],
  );

  const handleSelected = (record) => {
    const idx = _.findIndex(selected, s => s.code === record.code);
    const updatedSelected = [...selected];

    if (idx >= 0) {
      // Exists - remove
      updatedSelected.splice(idx, 1);
    } else {
      // Not exists - insert
      updatedSelected.push({ ...record, type: renderType(type) });
    }

    setSelected(updatedSelected);
  }

  const renderType = (recordType) => TYPES.find(t => t.value === recordType)?.label || recordType;

  useEffect(() => {
    throttledSearch({ search: text, type, version });
  }, [text, type]);

  useEffect(() => {
    onSelect(selected);
  }, [selected]);

  return (
    <>
      {isMultiple && (
        <Tabs
          value={activeTab}
          onChange={(e, newTab) => setActiveTab(newTab)}
          variant="fullWidth"
        >
          <Tab label={t('Resursų paieška')} />
          <Tab label={`${t('Pasirinkti resursai')} (${selected.length})`} />
        </Tabs>
      )}

      {activeTab === TABS.SEARCH && (
        <div>
          <div style={{ flexGrow: 1, marginTop: '-15px' }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Field
                  label={`${t('Resurso pavadinimas')}...`}
                  name="text"
                  value={text}
                  onChange={({ value }) => setText(value)}
                  onFocus={(e) => e.currentTarget.select()}
                />
              </Grid>

              <Grid item xs>
                {TYPES.map(({ value, label }) => (
                  <div key={value} style={{ paddingTop: '14px', display: 'inline-block' }}>
                    <Field
                      key={value}
                      label={`${t(label)}`}
                      name={value}
                      type="radio"
                      value={type === value}
                      onChange={({ name }) => setType(name)}
                      disabled={!!lockedType}
                    />
                  </div>
                ))}
              </Grid>
            </Grid>
          </div>

          <TableContainer style={{ maxHeight: 400, marginTop: '10px' }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {isMultiple && <TableCell />}
                  <TableCell>{t('Kodas')}</TableCell>
                  <TableCell>{t('Pavadinimas')}</TableCell>
                  <TableCell>{t('Tipas')}</TableCell>
                  <TableCell>{t('Mato vnt.')}</TableCell>
                  <TableCell>{t('Kaina, EUR be PVM')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!!records && records.length > 0 ? records.map((record) => (
                  <TableRow
                    key={record.code}
                    className={[
                      classes.selectableRow,
                      !!selected.find(s => s.code === record.code) && !isMultiple ? classes.selectedRow : '',
                    ].join(' ')}
                    onClick={!isMultiple ? () => setSelected([{ ...record }]) : undefined}
                  >
                    {isMultiple && (
                      <TableCell>
                        <input
                          type="checkbox"
                          onChange={e => handleSelected(record)}
                          checked={!!selected?.find(s => s.code === record.code)}
                        />
                      </TableCell>
                    )}
                    <TableCell>{highlightText(record.code, text)}</TableCell>
                    <TableCell>{highlightText(record.title, text)}</TableCell>
                    <TableCell>{record.type}</TableCell>
                    <TableCell
                      style={{ color: !isMultiple && originalItem && !!originalItem?.unit && originalItem?.unit != record.unit ? 'red' : 'inherit' }}
                    >
                      {record.unit}
                    </TableCell>
                    <TableCell align="right">{twoOrMoreDecimals(record.price || 0)}</TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={isMultiple ? 6 : 5} align="center">{t('Nieko nerasta')}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>


        </div>
      )}

      {isMultiple && activeTab === TABS.SELECTED && (
        <TableContainer style={{ maxHeight: 400, marginTop: '10px' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t('Tipas')}</TableCell>
                <TableCell>{t('Kodas')}</TableCell>
                <TableCell>{t('Pavadinimas')}</TableCell>
                <TableCell>{t('Mato vnt.')}</TableCell>
                <TableCell>{t('Kaina, EUR be PVM')}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {!!selected && selected.length > 0 ? selected.map((record) => (
                <TableRow
                  key={record.code}
                  className={classes.selectableRow}
                >
                  <TableCell>{record.type}</TableCell>
                  <TableCell>{record.code}</TableCell>
                  <TableCell>{record.title}</TableCell>
                  <TableCell>{record.unit}</TableCell>
                  <TableCell align="right">{twoOrMoreDecimals(record.price || 0)}</TableCell>
                  <TableCell>
                    <a
                      href="#"
                      onClick={() => handleSelected(record)}
                    >
                      {t('Pašalinti')}
                    </a>
                  </TableCell>
                </TableRow>
              )) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">{t('Nieko nepasirinkta')}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}

export default ResourceSearch;