import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import TreeDataTableV2 from 'components/TreeDataTableV2/TreeDataTableV2';
import TableActionMenu from 'components/TreeDataTableV2/TableActionMenu';
import api from 'utils/api';
import { toast, twoOrMoreDecimals, isAdminCurrent } from 'utils/helpers';
import { useHistory } from 'react-router-dom';

const TYPES = {
  GROUP: 'group',
  ITEM: 'item',
  VIRTUAL: 'virtual',
}

const ITEM_CONTEXTS = {
  SITE: 'site',
  SITE_PREPARATION: 'site-preparation',
  CONSTRUCTION: 'construction',
  DESIGN: 'design',
  OTHER_EXPENSES: 'other-expenses',
  RESERVE: 'reserve',
  UNDISTRIBUTED: 'undistributed',
};

const EstimateSummaryTable = ({
  config = {},
  summaryId,
  rows = [],
  onUpdate,
  onObjectSearch,
  onObjectEdit,
  onShowSettings,
  showRowOrderArrows
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleShowSettings = () => {
    !!onShowSettings && onShowSettings();
  }

  const handleChange = async ({ name, value, item }) => {

    try {
      const payload = { field: name, value };
      await api.put(`/estimate-summaries/${summaryId}/items/${item._id}`, payload);

      onUpdate();
    } catch (err) {
      console.error(err);

      toast(t('Nepavyko atnaujinti'), 'error');
    }
  };

  const handleReset = async (item) => {
    if (!window.confirm(t('Ar tikrai norite išvalyti reikšmes?'))) return;

    try {
      await api.put(`/estimate-summaries/${summaryId}/items/${item._id}/reset`);

      toast(t('Atlikta'), 'success');

      onUpdate();
    } catch (err) {
      console.error(err);

      toast(t('Nepavyko išvalyti'), 'error');
    }
  }

  const handleDelete = async (item) => {
    if (!window.confirm(t('Ar tikrai norite pašalinti?'))) return;

    try {
      await api.delete(`/estimate-summaries/${summaryId}/items/${item._id}`);

      toast(t('Pašalinta'), 'success');

      onUpdate();
    } catch (err) {
      console.error(err);

      toast(t('Nepavyko pašalinti'), 'error');
    }
  }

  const handleAdd = async (item) => {
    try {
      await api.post(`/estimate-summaries/${summaryId}/items`, item);

      if (item.parent) setExpandedRowKeys([item.parent]);

      toast(t('Pridėta'), 'success');
      onUpdate();
    } catch (err) {
      console.error(err);

      toast(t('Nepavyko pridėti'), 'error');
    }
  }

  const handleAddObject = async (item) => {
    onObjectSearch(item);

    if (item.parent) setExpandedRowKeys([item.parent]);
  }

  const handleEditObject = async (estimateObjectId, openInNewWindow = false) => {
    onObjectEdit(estimateObjectId, openInNewWindow);
  }

  const handleReorder = async (id, order) => {
    try {
      await api.put(`/estimate-summaries/${summaryId}/reorder-item`, { item: id, order });

      onUpdate();
    } catch (err) {
      console.error(err);

      toast(t('Nepavyko atnaujinti'), 'error');
    }
  };

  const renderActionMenu = ({
    name,
    value,
    onChange,
    item,
  }) => {

    let options = [];
    const suboptions = [];

    // Construction context can only have Estimate Objects as childs
    const isConstructionContext = item.context === ITEM_CONTEXTS.CONSTRUCTION;

    if (!item.parent && !isConstructionContext) {
      // Top-level item

      options.push({ title: t('Nauja eilutė'), handler: () => handleAdd({ title: t('Nauja eilutė'), type: TYPES.GROUP, order: item.order + 0.5 }) });

      suboptions.push({ title: t('Nauja eilutė'), handler: () => handleAdd({ title: t('Nauja eilutė'), parent: item._id, type: TYPES.ITEM, order: item.nodes.length + 0.5 }) });

      if (!item.context) {
        config?.template?.forEach(t => {
          suboptions.push({ title: t.title, handler: () => handleAdd({ ...t, order: item.order + 0.5 }) });
        });
      }
    } else if (item.parent && isConstructionContext) {
      // Nested item

      options.push({ title: t('Nauja eilutė'), handler: () => handleAdd({ title: t('Nauja eilutė'), parent: item.parent, type: TYPES.ITEM, order: item.order + 0.5 }) });

      if (!!item.estimateObject) {
        options.push({ title: t('Pridėti sąmatos skaičiavimą'), handler: () => handleAddObject({ title: t('Pridėti sąmatos skaičiavimą'), parent: item.parent, type: TYPES.ITEM, order: item.order + 0.6 }) });
        options.push({ title: t('Redaguoti sąmatos skaičiavimą'), handler: () => handleEditObject(item.estimateObject) });
        options.push({ title: t('Redaguoti sąmatos skaičiavimą (naujame lange)'), handler: () => handleEditObject(item.estimateObject, true) });
      }

    } else if (!item.parent && isConstructionContext) {
      // Top-level item (construction context)

      options.push({ title: t('Nauja eilutė'), handler: () => handleAdd({ title: t('Nauja eilutė'), parent: item.parent, type: TYPES.ITEM, order: item.order + 0.5 }) });

      suboptions.push({ title: t('Nauja eilutė'), handler: () => handleAdd({ title: t('Nauja eilutė'), parent: item._id, type: TYPES.ITEM, order: item.nodes.length + 0.5 }) });
      suboptions.push({ title: t('Pridėti sąmatos skaičiavimą'), handler: () => handleAddObject({ title: t('Pridėti sąmatos skaičiavimą'), parent: item._id, type: TYPES.ITEM, order: item.nodes.length + 0.5 }) });
    }

    const subitems = config?.template?.find(t => t.context === item.context)?.childs || [];
    if (!item.parent && subitems.length) {
      // Subitems for top-level items
      for (const subitem of subitems) {
        const newItem = {
          ...subitem,
          parent: item.parent || item._id,
          order: !item.parent ? item.nodes.length + 0.5 : item.order + 0.5,
        };

        suboptions.push({
          title: subitem.title, type: subitem.type, handler: () => handleAdd(newItem)
        });
      }
    } else if (item.parent && subitems.length) {
      // Subitems for nested items

      options.push({ title: t('Nauja eilutė'), handler: () => handleAdd({ title: t('Nauja eilutė'), parent: item.parent, type: TYPES.ITEM, order: item.order + 0.5 }) });

      for (const subitem of subitems) {
        const newItem = {
          ...subitem,
          parent: item.parent,
          order: item.order + 0.5 + (options.length * 0.1),
        };

        suboptions.push({
          title: subitem.title, type: subitem.type, handler: () => handleAdd(newItem)
        });
      }
    }

    if (suboptions.length) options.push({ title: <span style={{ marginRight: '25px' }}>{t('Įvesti detalizuotą eilutę')}</span>, childs: suboptions });

    options.push({ title: t('Nustatymai'), handler: () => handleShowSettings() });
    options.push({ title: t('Išvalyti reikšmes'), handler: () => handleReset(item) });
    options.push({ title: t('Pašalinti įrašą'), handler: () => handleDelete(item) });

    if (item.context === ITEM_CONTEXTS.UNDISTRIBUTED) {
      // Disable action menu for undistributed items
      options = [];
    }

    return (
      <div style={{ textAlign: 'right' }}>
        <TableActionMenu
          options={options}
        />
      </div>
    )
  };

  const renderTitleDisplay = ({ value, item }) => {
    if (item.context === ITEM_CONTEXTS.UNDISTRIBUTED) return value;

    return (
      <div style={{ position: 'relative' }}>
        {value}
        <div style={{ position: 'absolute', right: 0, top: 0, fontSize: '8px', fontWeight: 'normal', color: item.percentage == 100 ? 'green' : 'red' }}>
          {item.percentage || 0}%
        </div>
      </div>
    )
  };

  const renderOtherAmountDisplay = ({ value, item }) => (
    <div style={{ position: 'relative' }}>
      {twoOrMoreDecimals(value)}
      {item?.settings?.isAutoCosts && (
        <div style={{ position: 'absolute', left: 0, top: '50%', fontSize: '8px', fontWeight: 'normal', transform: 'translateY(-50%)' }}>
          {item?.settings?.autoCostsValue || 0}%
        </div>
      )}
    </div>
  );

  const renderDisplay = ({ value }) => twoOrMoreDecimals(value);
  const cellConfig = { style: { textAlign: 'right' } }
  const isReadonly = (item) => item.context === ITEM_CONTEXTS.UNDISTRIBUTED;

  const tableColumns = [
    { key: 'code', label: t('Skyrius'), readonly: isReadonly, columnConfig: { stiff: true, resize: true, pinLeft: true } },
    { key: 'title', label: t('Išlaidų aprašymas'), readonly: isReadonly, columnConfig: { resize: true }, renderDisplay: isAdminCurrent() ? renderTitleDisplay : undefined },
    { key: 'constructionAmount', label: t('Statybos montavimo darbai'), readonly: isReadonly, columnConfig: { resize: true }, renderDisplay, cellConfig },
    { key: 'equipmentAmount', label: t('Įrenginiai, baldai'), readonly: isReadonly, columnConfig: { resize: true }, renderDisplay, cellConfig },
    { key: 'otherAmount', label: t('Kitos išlaidos'), readonly: isReadonly, columnConfig: { resize: true }, renderDisplay: renderOtherAmountDisplay, cellConfig },
    { key: 'totals.priceWithoutVat', label: t('Kaina, EUR be PVM'), readonly: true, columnConfig: { resize: true }, renderDisplay, cellConfig, },
    { key: 'totals.priceWithVat', label: t('Kaina, EUR su PVM'), readonly: true, columnConfig: { resize: true }, renderDisplay, cellConfig },
    { key: 'actions', label: '', render: renderActionMenu, columnConfig: { stiff: true, pinRight: true }, cellConfig },
  ];

  return (
    <div
      className="estimate-summary-table-container"
      style={{
        // display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >

      <div style={{ height: `${window.innerHeight - 300}px` }}>
        <TreeDataTableV2
          showExpandAll
          gridTemplateColumns={'12% 39% 9% 9% 9% 9% 9% 4%'}
          rows={rows}
          columns={tableColumns}
          onChange={handleChange}
          expandedRowKeys={expandedRowKeys}
          showRowOrderArrows={showRowOrderArrows}
          onItemOrderChange={(id, order) => handleReorder(id, order)}
        />
      </div >

    </div>
  );
}

export default EstimateSummaryTable;