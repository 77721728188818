import React, { useState, useEffect, createContext, useMemo, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  Button,
  Grid,
  Tabs,
  Tab,
  InputLabel,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import {
  Save as SaveIcon,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';

import * as estimateSummaryService from 'services/estimateSummaries';
import * as projectService from 'services/projects';
import { Dashboard as DashboardLayout } from 'layouts';
import { FormField2 as Field, CommonPanel, NormVersionPicker, MenuCustom, TrialHandler, CommonDialog, AutoComplete, LicenceRequestForm, canAccess as canAccessUtil } from 'components';
import { timestampToDate, toast, twoOrMoreDecimals, scrollToElement, setElementHighlight, blobToFileDownload, getQueryVariable, getCurrentAccount } from 'utils/helpers';
import api from 'utils/api';
import { EDU_MODE } from 'utils/config';
import EstimateSummaryTable from './EstimateSummaryTable';
import EstimateSummarySettingsModal from './EstimateSummarySettingsModal';
import EstimateObjectImportModal from './EstimateObjectImportModal';
import EstimateSummaryPrintModal from './EstimateSummaryPrintModal';

import './EstimateSummary.css';

const SERVICE_CODE = 'estimates.summary';
const SERVICE_CODE_DB = 'SAM';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '15px',
    padding: theme.spacing(4),
    // maxWidth: '1200px',
  },
  titleCoefficients: {
    fontSize: '9px',
    padding: '0px 8px 2px',
  },
}));

const EstimateSummary = (props) => {
  const { projectId, summaryId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [record, setRecord] = useState(null);
  const [project, setProject] = useState(null);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [objectModalOpenForItem, setObjectModalOpenForItem] = useState(false);
  const [canAccessService, setCanAccessService] = useState(false);
  const [config, setConfig] = useState(null);
  const [isTrial, setIsTrial] = useState(!!getQueryVariable('trial'));
  const [showTrialMessage, setShowTrialMessage] = useState(getCurrentAccount().trialServices.includes(SERVICE_CODE));
  const [showRowOrderArrows, setShowRowOrderArrows] = useState(false);
  const { t } = useTranslation();
  const isNew = !record?._id;

  const fetchProject = async (projectId) => {
    try {
      const data = await projectService.show(projectId);
      setProject(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašą'), 'error');
    }
  }

  const fetchConfig = async () => {
    try {
      const data = await estimateSummaryService.getConfig();
      setConfig(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant konfigūraciją'), 'error');
    }
  }

  const checkLicence = async () => {
    try {
      const response = await api.post(`/licences/licence-valid`, { service: SERVICE_CODE_DB, rule: SERVICE_CODE });
      setCanAccessService(response.data.validLicence);
      return response.data.validLicence;
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant konfigūraciją'), 'error');
    }
  }

  const fetchRecord = async (id) => {
    try {
      const data = await estimateSummaryService.show(id, true);
      setRecord(data);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašą'), 'error');
    }
  }

  useEffect(() => {
    if (isTrial) return;

    if (!record?._id && summaryId) fetchRecord(summaryId);
    checkLicence().then(canAccess => {
      if (canAccess) fetchProject(projectId)
        .then(() => fetchConfig())
    });
  }, []);

  const handleRecordChange = ({ name, value }, submitAfter = false) => {
    const newRecord = {
      ...record,
      [name]: value,
    };

    setRecord(newRecord);

    if (submitAfter) handleSubmit(newRecord, submitAfter);
  }

  const handleSummaryDelete = async () => {
    if (!window.confirm(t('Ar tikrai norite pašalinti?'))) return;

    try {
      await estimateSummaryService.remove(summaryId);

      toast(t('Suvestinis skaičiavimas pašalintas'), 'success');
      history.push(`/projects/${projectId}/dashboard`);
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko pašalinti'), 'error');
    }
  };

  const handleRecalculate = async () => {
    try {
      await estimateSummaryService.recalculate(summaryId);

      fetchRecord(summaryId);
      toast(t('Perskaičiuota'), 'success');
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko perskaičiuoti'), 'error');
    }
  };

  const handleSubmit = async (data = null, hideToast = false) => {
    try {
      const payload = _.omit(data || record, 'items');
      payload.project = projectId;

      if (!summaryId) {
        const { _id } = await estimateSummaryService.create(payload);

        if (!hideToast) toast(t('Suvestinis skaičiavimas sukurtas'), 'success');
        history.replace(`/projects/${projectId}/summaries/${_id}`);
      } else {
        await estimateSummaryService.update(summaryId, payload);

        await fetchRecord(record._id);
        if (!hideToast) toast(t('Atnaujinta'), 'success');
      }
    } catch (err) {
      console.error(err);
      toast(t('Nepavyko išsaugoti'), 'error');
    }
  };

  const handleObjectSearch = (item) => {
    setObjectModalOpenForItem(item);
  };

  const handleSettingsModalClose = (shouldFetchRecord = false) => {
    setSettingsModalOpen(false);

    if (shouldFetchRecord) fetchRecord(summaryId);
  };

  const handleObjectEdit = (estimateObjectId, inNewWindow = false) => {
    const estimateObjectUrl = `/projects/${projectId}/objects/${estimateObjectId}`;

    if (!inNewWindow) {
      history.push(estimateObjectUrl);
    } else {
      window.open(estimateObjectUrl, '_blank');
    }
  };

  const handleObjectImportSelected = async (objectId, shouldMatchVersion = false) => {
    try {
      const payload = {
        parent: objectModalOpenForItem.parent,
        estimateObject: objectId,
      };

      if (shouldMatchVersion) {
        payload.matchVersion = true;
      }

      await api.post(`/estimate-summaries/${summaryId}/items`, payload);

      toast(t('Pridėta'), 'success');

      fetchRecord(summaryId);
    } catch (err) {
      console.error(err);

      toast(t('Nepavyko pridėti'), 'error');
    }

    setObjectModalOpenForItem(null);
  }

  const renderTrial = () => {

    return (
      <TrialHandler
        service="estimates.summary"
      />
    )
  }

  const renderTrialMessage = () => {

    return EDU_MODE ? null : (
      <div style={{ margin: 10 }}>
        <Alert
          variant="filled"
          severity="info"
        // onClose={() => this.setState({ showTrialMessage: false })}
        >
          {t('Jūs naudojate bandomąją (laikinai nemokamą) paslaugos versiją.')}
        </Alert>
      </div>
    )
  }

  const renderLicenceRequest = () => {

    return (
      <LicenceRequestForm
        serviceCode={SERVICE_CODE}
      />
    )
  }

  return (
    <>
      <DashboardLayout
        titleComponent={!isNew &&
          <Grid container spacing={2}>
            <Grid item style={{ width: '260px' }}>
              <div style={{ display: 'flex' }}>
                <Field
                  name="title"
                  onChange={handleRecordChange}
                  value={record?.title}
                  label={t('Suvestinio skaičiavimo pavadinimas')}
                  shrink
                  style={{ width: 220 }}
                />
                <IconButton
                  color="primary"
                  onClick={() => handleSubmit()}
                  style={{ marginTop: 10 }}
                  size="small"
                  disableFocusRipple={true}
                  disableRipple={true}
                >
                  <SaveIcon style={{ width: 28, height: 28 }} />
                </IconButton>
              </div>
            </Grid>
            <Grid item style={{ paddingLeft: 32 }}>
              <NormVersionPicker
                value={record?.version?.code}
                name="version"
                onChange={({ value }) => handleRecordChange({ name: 'version', value }, true)}
                label={t('Kainyno versija')}
                style={{ marginTop: 16, width: '180px' }}
                type="calculations"
                variant="estimates"
                allowBlank
              />
            </Grid>
          </Grid>
        }
        breadcrumbs={[
          { title: t('Projektai'), to: '/projects/' },
          { title: project?.title || '-', to: `/projects/${projectId}/dashboard` },
          { title: t('Suvestiniai skaičiavimai') },
          { title: `${!summaryId ? t('Naujas skaičiavimas') : (record?.title || '-')} ${record?.version?.version ? '(' + t('Kainynas ') + ' ' + record?.version?.version + ')' : ''}` },
        ]}
        breadcrumbsObject={!isNew && (
          <>
            <div style={{ paddingRight: 12 }}>
              <FormControlLabel
                control={<Checkbox checked={showRowOrderArrows}
                  onChange={() => setShowRowOrderArrows(!showRowOrderArrows)}
                  name="showRowOrderArrowsCheckbox" />}
                label="Keisti eilučių išdėstymą?"
                style={{ paddingLeft: 20 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSettingsModalOpen(true)}
                style={{ marginRight: 10 }}
              >
                {t('Išlaidų nustatymai')}
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setPrintModalOpen(true)}
                style={{ marginRight: 10 }}
              >
                {t('Spausdinti')}
              </Button>

              <MenuCustom
                buttonText={t('Veiksmai')}
                endIcon={<MoreVertIcon />}
                buttonVariant="contained"
                buttonColor="primary"
                items={[
                  // { label: t('Nustatymai'), handler: () => setSettingsModalOpen(true) },
                  { label: t('Perskaičiuoti'), handler: handleRecalculate },
                  { label: t('Pašalinti'), handler: handleSummaryDelete },
                ]}
              />
            </div>
          </>
        )}
        beforeMainContent={!isTrial && showTrialMessage && renderTrialMessage()}
      >
        <div className={classes.root} style={{ paddingTop: 12 }}>
          {!!record?._id && (
            <div style={{ marginBottom: 20 }}>
              <table className="summary-debug-table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>{t('Statybos montavimo darbai')}</th>
                    <th>{t('Įrenginiai, baldai')}</th>
                    <th>{t('Kitos išlaidos')}</th>
                    <th>{t('Suma, be PVM')}</th>
                    <th>{t('PVM suma')}</th>
                    <th>{t('Suma, su PVM')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(record?.constructionAmount) || '0.00'} EUR</td>
                    <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(record?.equipmentAmount) || '0.00'} EUR</td>
                    <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(record?.otherAmount) || '0.00'} EUR</td>
                    <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(record?.totals?.priceWithoutVat) || '0.00'} EUR</td>
                    <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(record?.totals?.vatAmount) || '0.00'} EUR</td>
                    <td style={{ textAlign: 'right' }}>{twoOrMoreDecimals(record?.totals?.priceWithVat) || '0.00'} EUR</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {!isTrial ? canAccessService ? (
            <CommonPanel>
              {isNew && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field name="title" onChange={handleRecordChange} value={record?.title} label={t('Suvestinio skaičiavimo pavadinimas')} shrink />
                    <Typography variant="body1">
                      {t('Įveskite naujo susvestinio skaičiavimo pavadinimą ir išsaugokite, kad galėtumėte pradėti skaičiavimus')}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      startIcon={<SaveIcon />}
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                      style={{ marginTop: 15 }}
                      disabled={!record?.title}
                    >
                      Išsaugoti
                    </Button>
                  </Grid>
                </Grid>
              )}

              {!isNew && <EstimateSummaryTable
                config={config}
                summaryId={summaryId}
                rows={record?.items || []}
                onUpdate={() => fetchRecord(summaryId)}
                onObjectSearch={handleObjectSearch}
                onObjectEdit={handleObjectEdit}
                onShowSettings={() => setSettingsModalOpen(true)}
                showRowOrderArrows={showRowOrderArrows}
              />}
            </CommonPanel>
          ) : renderLicenceRequest() : renderTrial()}
        </div >

      </DashboardLayout >

      {settingsModalOpen && (
        <EstimateSummarySettingsModal
          rows={record?.items || []}
          summaryId={summaryId}
          onUpdate={() => fetchRecord(summaryId)}
          onClose={handleSettingsModalClose}
        />
      )}

      {printModalOpen && (
        <EstimateSummaryPrintModal
          summaryId={summaryId}
          onClose={() => setPrintModalOpen(false)}
        />
      )}

      {objectModalOpenForItem && (
        <EstimateObjectImportModal
          // projectId={projectId}
          versionCode={record?.version?.code}
          onClose={() => setObjectModalOpenForItem(null)}
          onSelect={(estimateObjectId, shouldMatchVersion = false) => handleObjectImportSelected(estimateObjectId, shouldMatchVersion)}
        />
      )}
    </>
  );
};

export default EstimateSummary;