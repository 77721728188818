import React, { Component, Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  withStyles,
  withWidth,
  Drawer,
  LinearProgress,
  Container,
  Breadcrumbs,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Sidebar, Topbar, AccountDialog } from './components';

import styles from './styles';
import { history } from 'utils/history';
import { getCurrentAccount } from 'utils/helpers';

import { closeAccountDialog, setAccount } from 'views/App/_actions';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    const isMobile = ['xs', 'sm'].includes(props.width);

    this.state = {
      isOpen: !isMobile,
      showDemoAlert: false,
    };
  }

  componentDidMount() {

    const account = getCurrentAccount();
    if (account.isDefault) {
      // toast('Jūs naudojate riboto funkcionalumo DEMO aplinką. Sistemos naudojimui registruokite paskyrą.', 'info');
      this.setState({ showDemoAlert: true });
    }
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleBackButton = () => {
    if (this.props.onBackClick) {
      this.props.onBackClick();
    } else {
      history.goBack();
    }
  }

  handleToggleOpen = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleAccountDialogClose = (accountId) => {
    const { dispatch, history } = this.props;

    dispatch(closeAccountDialog());

    if (accountId) {
      dispatch(setAccount(accountId));
      history.push('/');
    }
  }

  handleCreateAccountClick = () => {
    const { dispatch, history } = this.props;

    dispatch(closeAccountDialog());
    history.push('/companies/join');
  }

  renderDemoAlert() {
    const { t } = this.props;

    return (
      <Container fixed>
        <Alert
          variant="filled"
          severity="info"
          onClose={() => this.setState({ showDemoAlert: false })}
        >
          <Link to="/companies/join" style={{ color: '#ffffff', fontWeight: 'bold', textDecoration: 'underline' }}>
            {t('Jūs naudojate riboto funkcionalumo DEMO aplinką. Sistemos naudojimui registruokite paskyrą.')}
          </Link>
        </Alert>
      </Container>
    )
  }

  renderBreadcrumbs() {
    const { breadcrumbs, breadcrumbsObject, t } = this.props;

    if (!breadcrumbs) return null;

    return (
      <div style={{ padding: '0px 20px' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            {t('Pradžia')}
          </Link>
          {_.map(breadcrumbs, item =>
            item.to ? (
              <Link key={item.title} color="inherit" to={item.to}>
                {item.title}
              </Link>
            ) : (
              <Typography key={item.title} color="textPrimary">
                {item.title}
              </Typography>
            )
          )}

        </Breadcrumbs>
        {breadcrumbsObject != null &&
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-16px', marginBottom: '5px' }}>
            {breadcrumbsObject}
          </div>
        }
      </div>
    );
  }

  render() {
    const {
      classes,
      width,
      title,
      titleComponent,
      children,
      specialMenu,
      specialMenuTitle,
      isAccountDialogOpen,
      beforeMainContent,
    } = this.props;
    const { isOpen } = this.state;

    const isMobile = ['xs', 'sm'].includes(width);
    const shiftTopbar = isOpen && !isMobile;
    const shiftContent = isOpen && !isMobile;

    return (
      <Fragment>
        <Topbar
          className={classNames(classes.topbar, {
            [classes.topbarShift]: shiftTopbar
          })}
          isSidebarOpen={isOpen}
          onToggleSidebar={this.handleToggleOpen}
          onBackButton={this.handleBackButton}
          title={title}
          titleComponent={titleComponent}
        />
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          onClose={this.handleClose}
          open={isOpen}
          variant={isMobile ? 'temporary' : 'persistent'}
        >
          <Sidebar
            className={classes.sidebar}
            specialMenu={specialMenu}
            specialMenuTitle={specialMenuTitle}
            onToggleSidebar={this.handleToggleOpen}
          />
        </Drawer>
        <main
          className={classNames(classes.content, {
            [classes.contentShift]: shiftContent
          })}
        >
          {this.renderBreadcrumbs()}
          {/* {showDemoAlert ? this.renderDemoAlert() : null} */}
          {beforeMainContent}
          {children}
          {/* <Footer /> */}
          {isAccountDialogOpen ? (
            <AccountDialog onCreateClick={this.handleCreateAccountClick} open={isAccountDialogOpen} onClose={this.handleAccountDialogClose} />
          ) : null}
        </main>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ app, dispatch }) => {
  return { ...app, dispatch };
};

export default compose(
  withRouter,
  withStyles(styles),
  withWidth(),
  connect(mapStateToProps),
  withTranslation(),
)(Dashboard);
