import React, { useContext, useReducer, useMemo, useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormField2 as Field } from 'components';
import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  HeaderCell,
  Cell,
} from "@table-library/react-table-library/table";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";
import { Virtualized } from "@table-library/react-table-library/virtualized";
import { useTree, CellTree, TreeExpandClickTypes } from "@table-library/react-table-library/tree";
import {
  Storage as StorageIcon,
  Search as SearchIcon,
  UnfoldMore as UnfoldMoreIcon,
  UnfoldLess as UnfoldLessIcon,
  ChevronRight as ChevronRightIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons';
import { IconButton, ButtonGroup, Checkbox, FormControlLabel, Button, Typography } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TreeEditableCellV2 from './TreeEditableCellV2';

const ROW_HEIGHT = 36;

const TreeDataTableV2 = (props) => {
  const { t } = useTranslation();
  const {
    rows: tableRows = [],
    columns: tableColumns = [],
    rowHeight = ROW_HEIGHT,
    onChange: onCellChange,
    gridTemplateColumns = '12% 42% 7% 7% 7% 7% 10% 7% 1%',
    isVirtualized = true,
    expandedRowKeys = [],
    showRowOrderArrows = false,
    onItemOrderChange,
    showExpandAll = false,
  } = props;

  const data = { nodes: tableRows };

  const theme = useTheme({
    Table: `
        --data-table-library_grid-template-columns:  ${gridTemplateColumns};
      `,
    BaseCell: `
      &:nth-of-type(1) {
        left: 0px;
      }

      &:nth-of-type(2) {
        left: 110px;
      }
      &:last-of-type{
        right: 0px;
      }
    `,
  });

  const tree = useTree(data, {
    onChange: () => { },
  }, {
    // treeYLevel: 1,
    indentation: 5,
    clickType: TreeExpandClickTypes.ButtonClick,
    treeIcon: {
      size: '10px',
      // margin: '4px',
      iconRight: <>&#x25BA;</>,
      iconDown: <>&#x25BC;</>,
    },
  });

  useEffect(() => {
    if (!expandedRowKeys?.length) return;

    tree.fns.onAddByIds(expandedRowKeys);
  }, [expandedRowKeys]);

  const handleCellChange = ({ name, value, item }) => {
    console.log('handleCellChange', { name, value, item });
    onCellChange({ name, value, item });
  };

  const handleExpandAllToggle = (e) => {
    e.preventDefault();

    if (!tree?.state?.ids?.length) {
      tree.fns.onAddByIds(tableRows.map(({ _id }) => _id));
    } else {
      tree.fns.onRemoveAll();
    }
  }

  return (
    <div style={{
      // display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
      {isVirtualized ? (
        <div style={{ height: props.height || `${window.innerHeight - 300}px` }}>
          <Table
            data={data}
            layout={{
              isDiv: true,
              fixedHeader: true,
              custom: true,
            }}
            tree={tree}
            className="tree-table"
            theme={theme}
          >
            {(tableList) => (
              <Virtualized
                tableList={tableList}
                rowHeight={rowHeight}
                header={() => (
                  <HeaderRow>
                    {tableColumns.map((column, idx) => idx === 0 && showExpandAll ? (
                      <HeaderCell key={column.key} {...column?.columnConfig}>
                        <a className="tree-expand-all" href="#" onClick={handleExpandAllToggle}>
                          {!tree?.state?.ids?.length ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
                        </a> {column.label}
                      </HeaderCell>
                    ) : (
                      <HeaderCell key={column.key} {...column?.columnConfig}>
                        {!column.renderHeader ? column.label : column.renderHeader(column, tree)}
                      </HeaderCell>
                    ))}
                  </HeaderRow>
                )}
                body={(item, rowIdx) => (
                  <Row
                    id={item._id}
                    item={item}
                    style={null}
                    className={`tree-row-type-${item.type} row-key-${item._id}`}
                  >
                    {tableColumns.map((column, colIdx) => (
                      <TreeCell
                        item={item}
                        colIdx={colIdx}
                        column={column}
                        key={`${item._id}-${column.key}`}
                        handleCellChange={handleCellChange}
                        showRowOrderArrows={showRowOrderArrows}
                        onItemOrderChange={onItemOrderChange}
                      />
                    ))}
                  </Row>
                )}
              />
            )}
          </Table>
        </div >
      ) : (
        <Table
          data={data}
          layout={{
            isDiv: true,
            fixedHeader: false,
            custom: true,
          }}
          tree={tree}
          className="tree-table"
          theme={theme}
        >
          {(tableList) => (
            <>
              <Header>
                <HeaderRow>
                  {tableColumns.map((column) => (
                    <HeaderCell key={column.key} {...column?.columnConfig}>
                      {column.label}
                    </HeaderCell>
                  ))}
                </HeaderRow>
              </Header>

              <Body>
                {tableList.map((item, rowIdx) => (
                  <Row
                    id={item._id}
                    item={item}
                    style={null}
                    className={`tree-row-type-${item.type} row-key-${item._id}`}
                  >
                    {tableColumns.map((column, colIdx) => (
                      <TreeCell
                        item={item}
                        colIdx={colIdx}
                        column={column}
                        key={`${item._id}-${column.key}`}
                        handleCellChange={handleCellChange}
                      />
                    ))}
                  </Row>
                ))}
              </Body>
            </>
          )}
        </Table>
      )}

    </div>
  );
}

const TreeCell = (props) => {
  const {
    colIdx,
    item,
    column,
    handleCellChange,
    showRowOrderArrows,
    onItemOrderChange,
  } = props;

  const shouldRenderTreeExpander = !!item.nodes?.length && colIdx === 0;
  const shouldRenderRowOrder = showRowOrderArrows && colIdx === 0 && item.type != 'virtual';

  const cellProps = {
    name: column.key,
    value: _.get(item, column.key), // Using lodash instead of `item[column.key]` to handle nested keys i.e. 'totals.priceWithoutVat'
    onChange: handleCellChange,
    item,
    readonly: column?.readonly,
    renderDisplay: column?.renderDisplay,
  }

  const cell = !!column?.render
    ? column.render(cellProps)
    : (
      <TreeEditableCellV2
        {...cellProps}
        inputProps={{ type: 'text' }}
      />

      // <Cell key={`${item._id}-${column.key}`} {...column?.cellConfig}>
      //   {column.hasOwnProperty('render') ? column.render(item) : item[column.key]}
      // </Cell>
    )

  let cellStyle = {};
  if (shouldRenderTreeExpander) {
    cellStyle = {
      ...cellStyle,
      paddingLeft: `${(item.level || 0) * 5}px`,
    };
  } else if (item.hasOwnProperty('level') && colIdx === 0) {
    cellStyle = {
      ...cellStyle,
      paddingLeft: `${item.level * 5 + 17}px`,
    };
  }

  if (column?.cellConfig?.style) {
    cellStyle = {
      ...cellStyle,
      ...column.cellConfig.style,
    };
  }

  return shouldRenderTreeExpander ? (
    <CellTree
      item={item}
      pinLeft
      style={cellStyle}
      treeIcon={{
        // margin: string,
        // size: string,
        // noIconMargin: string,
        // iconDefault: CustomIcon<T>,
        // iconRight: CustomIcon<T>,
        // iconDown: CustomIcon<T>,
        iconDown: <ExpandMoreIcon />,
        // iconDefault: <span>dfl-</span>,
        iconRight: <ChevronRightIcon />,
      }}
    >
      {cell}
      {shouldRenderRowOrder ? <div style={{position: 'absolute', left: '80%', top: '3px'}}>
        <ButtonGroup orientation="vertical">
          <IconButton
            size="small"
            edge="start"
            disabled={item.parentNode.nodes[0]._id == item._id}
            color="primary"
            style={{ height: 15 }}
            onClick={() => onItemOrderChange({id: item._id, order: item.order - 1})}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton
            size="small"
            edge="start"
            disabled={item.parentNode.nodes[item.parentNode.nodes.length - 1]._id == item._id}
            color="primary"
            style={{ height: 15 }}
            onClick={() => onItemOrderChange({id: item._id, order: item.order + 1})}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </ButtonGroup>
      </div> : null} 
    </CellTree>
  ) : (
    <Cell
      {...column?.cellConfig}
      style={cellStyle}
      pinLeft
    >
      {cell}
      {shouldRenderRowOrder ? <div style={{position: 'absolute', left: '80%', top: '3px'}}>
        <ButtonGroup orientation="vertical">
          <IconButton
            size="small"
            edge="start"
            disabled={item.parentNode.nodes[0]._id == item._id}
            color="primary"
            style={{ height: 15 }}
            onClick={() => onItemOrderChange({id: item._id, order: item.order - 1})}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton
            size="small"
            edge="start"
            disabled={item.parentNode.nodes[item.parentNode.nodes.length - 1]._id == item._id}
            color="primary"
            style={{ height: 15 }}
            onClick={() => onItemOrderChange({id: item._id, order: item.order + 1})}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
        </ButtonGroup>
      </div> : null} 
    </Cell>
  );
}

export default TreeDataTableV2;