import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio,
  withStyles,
  InputBase,
} from '@material-ui/core';

import NtkFullTreeSelect from './components/NtkFullTreeSelect';

import styles from './styles';

class FormField2 extends React.Component {
  _isMounted = false;
  state = {
    options: [],
    naked: false,
  }

  componentDidMount() {
    this._isMounted = true;

    const { naked } = this.props;
    this.setState({ naked });

    if (this.props.options) {
      this.getOptions();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.getOptions();
    }
  }

  async getOptions() {
    if (!this._isMounted) {
      return;
    }

    const fieldOptions = _.isFunction(this.props.options) ? await this.props.options() : this.props.options;

    const options = _.map(fieldOptions, ({ value, label }) => (
      <MenuItem key={`${label}-${value}`} value={value} style={this.props.optionStyle}>
        {label}
      </MenuItem>
    ));

    this.setState({ options });
  }

  renderFormField({ type, name, label, value, onChange, onBlur, error, placeholder, ...attrs }) {
    const { classes } = this.props;
    const { naked } = this.state;
    name = attrs.field ? attrs.field.name : name;

    switch (type) {

      case 'select':
        return (
          <FormControl
            className={classes.formControl}
            variant="outlined"
            fullWidth
            margin="dense"
            style={attrs.style}
            disabled={!!attrs.disabled}
          >
            <InputLabel
              id={`${name}-simple-select-label`}
              shrink={!!placeholder ? true : undefined}
            >
              {label}
            </InputLabel>
            <Select
              labelId={`${name}-simple-select-label`}
              id={`${name}-simple-select`}
              value={value || (attrs.multiple ? [] : '')}
              onChange={(e) => onChange({ name, value: e.target.value })}
              multiple={attrs.multiple}
              MenuProps={{
                style: attrs.selectStyle
              }}
              displayEmpty
            // renderValue={value => console.log({ value })}
            // renderValue={value => value?.length ? Array.isArray(value) ? value.join(', ') : value : placeholder}
            // renderValue={(value) => (value !== '' ? value : placeholder)}
            >
              {this.state.options}
            </Select>
          </FormControl>
        );

      case 'ntk-full-tree-select':
        return (
          <NtkFullTreeSelect
            name={name}
            value={value || ''}
            onChange={({ value }) => onChange({ name, value })}
          />
        )

      case 'checkbox':
        const checkbox = (
          <Checkbox
            name={name}
            checked={value || false}
            onChange={({ target }) => onChange({ name, value: target.checked })}
            style={attrs.style}
            disabled={attrs.disabled}
          />
        );

        return label ? (
          <FormControlLabel
            control={checkbox}
            label={label}
          />
        ) : checkbox;

      case 'radio':
        const radio = (
          <Radio
            name={name}
            checked={value || false}
            onChange={({ target }) => onChange({ name, value: target.checked })}
            style={attrs.style}
            disabled={attrs.disabled}
          />
        );

        return label ? (
          <FormControlLabel
            control={radio}
            label={label}
          />
        ) : radio;

      case 'date':
        const dateValue = !value ? '' : moment(value).format('YYYY-MM-DD');
        return (
          <TextField
            fullWidth
            margin="dense"
            type="date"
            error={error !== undefined}
            name={name}
            label={label}
            value={dateValue}
            placeholder={placeholder}
            helperText={attrs.helperText || null}
            variant="outlined"
            onChange={onChange ? e => onChange({ name, value: e.target.value }) : undefined}
            onBlur={onBlur ? e => onBlur({ name, value: e.target.value }) : undefined}
            onFocus={attrs.onFocus}
            onKeyDown={attrs.onKeyDown}
            className={[classes.textField, attrs.className, attrs.multiline ? classes.textarea : ''].join(' ')}
            style={{ marginTop: 8, ...attrs.style }}
            disabled={attrs.disabled}
            InputLabelProps={{ shrink: true }}
          />
        )

      default:
        const inputValue = type === 'number' ? (!_.isNil(value) ? (!_.isNaN(value) ? value : '') : '') : value || '';
        const numberProps = type === 'number'
          ? {
            min: this.props.min,
            max: this.props.max,
            step: this.props.step,
          }
          : {};
        const onFocus = attrs.onFocus ? attrs.onFocus : (type === 'number' ? (e) => e.target.select() : undefined);
        return !naked ? (
          <TextField
            fullWidth
            margin="dense"
            type={type}
            error={error !== undefined}
            name={name}
            label={label}
            value={inputValue}
            placeholder={placeholder}
            helperText={attrs.helperText || null}
            variant="outlined"
            onChange={onChange ? e => onChange({ name, value: e.target.value }) : undefined}
            onBlur={onBlur ? e => onBlur({ name, value: e.target.value }) : undefined}
            onFocus={onFocus}
            onKeyDown={attrs.onKeyDown}
            className={[classes.textField, attrs.className, attrs.multiline ? classes.textarea : ''].join(' ')}
            style={attrs.style}
            disabled={attrs.disabled}
            inputProps={numberProps}
            rows={attrs.rows}
            multiline={attrs.multiline}
            InputLabelProps={{ shrink: attrs.shrink }}
            required={attrs.required}
          />
        ) : (
          <InputBase
            fullWidth
            type={type}
            error={error !== undefined}
            name={name}
            value={inputValue}
            placeholder={placeholder}
            onChange={onChange ? e => onChange({ name, value: e.target.value }) : undefined}
            onBlur={onBlur ? e => onBlur({ name, value: e.target.value }) : undefined}
            onFocus={onFocus}
            onKeyDown={attrs.onKeyDown}
            className={[classes.textFieldNaked, attrs.className, attrs.multiline ? classes.textarea : ''].join(' ')}
            style={attrs.style}
            disabled={attrs.disabled}
            inputProps={numberProps}
            rows={attrs.rows}
            multiline={attrs.multiline}
            required={attrs.required}
          />
        )
    }


  }

  render() {
    const { wrapperStyle = {}, ...rest } = this.props;

    return (
      <div style={wrapperStyle}>

        {this.renderFormField(rest)}
      </div>
    );
  }
}

export default withStyles(styles)(FormField2);