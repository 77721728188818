import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const AutoComplete = (props) => {
  const t = i18next.t.bind(i18next)
  const { options, disabled, label, style, onCreate, name, creatable, createText = t('Sukurti'), ...rest } = props;
  const [value, setValue] = useState(props?.value || (rest?.multiple ? [] : null));
  const filter = createFilterOptions();

  const AutoCompleteCreatable = (
    <Autocomplete
      {...(rest?.multiple ? { defaultValue: value } : { value: value })}
      // value={value}
      size="small"
      disabled={disabled}
      className={props.className}
      onChange={(event, newValue) => {
        props.onChange(newValue);
        if (newValue && !options.find(val => val.title == newValue.title) && creatable) {
          onCreate(newValue);
        } else {
          if (typeof newValue === 'string') {
            setValue({
              title: newValue,
            });
          } else if (newValue && newValue.inputValue && creatable) {
            // Create a new value from the user input
            setValue({
              title: newValue.inputValue,
            });
          } else {
            setValue(newValue);
          }
        }

      }}

      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== '' && creatable) {
          filtered.push({
            inputValue: params.inputValue,
            title: (createText + ' \'' + params.inputValue + '\'')
          });
        }

        return filtered;
      }}

      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="autoComplete-creatable"
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(option) => option.title}
      style={style}
      // freeSolo
      multiple={rest?.multiple}
      renderInput={(params) => (
        <TextField {...params} {...(rest?.inputParams || {})} label={label} variant="outlined" />
      )}
    />
  );
  return AutoCompleteCreatable;
}

export default withTranslation()(AutoComplete);