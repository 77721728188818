import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CommonDialog,
  CommonPanel,
} from 'components';
import { Button } from '@material-ui/core';
import TreeDataTableV2 from 'components/TreeDataTableV2/TreeDataTableV2';
import api from 'utils/api';
import { timestampToDate, toast, twoOrMoreDecimals, scrollToElement, setElementHighlight, blobToFileDownload, getQueryVariable, getCurrentAccount } from 'utils/helpers';
import { FormField2 as Field } from 'components';
import * as estimateSummaryService from 'services/estimateSummaries';

const TYPES = {
  GROUP: 'group',
  ITEM: 'item',
}

const ITEM_CONTEXTS = {
  SITE: 'site',
  SITE_PREPARATION: 'site-preparation',
  CONSTRUCTION: 'construction',
  DESIGN: 'design',
  OTHER_EXPENSES: 'other-expenses',
  RESERVE: 'reserve',
};

const EstimateSummarySettingsModal = ({
  summaryId,
  onClose,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const checkRowIsEditable = (row) => row.context !== ITEM_CONTEXTS.CONSTRUCTION;

  const fetchRecord = async (id) => {
    try {
      const { items } = await estimateSummaryService.show(id, false);

      const initialTableRows = items.map(row => {
        let newRow;
        if (row.type === TYPES.GROUP && row.context === ITEM_CONTEXTS.CONSTRUCTION) {
          // Construction items does not have auto costs nor the VAT by design
          newRow = { ...row, nodes: [] };
        } else {
          newRow = row;
        }

        newRow.isEditable = checkRowIsEditable(newRow);
        newRow?.nodes?.filter((nodeRow) => {
          nodeRow.isEditable = checkRowIsEditable(nodeRow);
        });

        return newRow
      });

      setRows(items);
      setTableRows(initialTableRows);
    } catch (err) {
      console.error(err);
      toast(t('Klaida įkeliant įrašą'), 'error');
    }
  }

  useEffect(() => {
    fetchRecord(summaryId);
  }, []);

  const flatStructure = rows.reduce((flatRows, row) => {
    flatRows.push({ label: row.code ? `${row.code} ${row.title}` : row.title, value: row._id, parent: row.parent });

    // Combine childs
    row.nodes.forEach((node, nodeIdx) => {
      flatRows.push({
        label: row.code ? `${_.trim([row.code, (nodeIdx + 1)].join('.'), '.')} ${node.title}` : node.title,
        value: node._id,
        parent: node.parent,
        isAutoCosts: node.settings.isAutoCosts || false,
      });
    });
    return flatRows;
  }, []);

  const handleClose = (shouldTriggerUpdate = false) => {
    !!onClose && onClose(shouldTriggerUpdate);
  }

  const submitChange = async () => {

    try {
      await api.put(`/estimate-summaries/${summaryId}/settings`, rows);

      onUpdate();
    } catch (err) {
      console.error(err);

      toast(t('Nepavyko atnaujinti'), 'error');
    }
  };

  const handleChange = ({ name, value, item }) => {
    const newRows = tableRows.map(row => {
      // Change on top-level row
      if (row._id === item._id) {
        if (name === 'settings.autoCostsSumItems') {
          // Set childs autoCostsSumItems
          row.nodes.forEach(node => {
            node.settings.autoCostsSumItems = [row._id];
          });
        }

        if (name === 'settings.isAutoCosts' && !value) {
          row.nodes.forEach(node => {
            node.settings.isAutoCosts = value;
          });
        }

        return _.set(row, name, value);
      }

      if (row.nodes.length) {
        const newNodes = row.nodes.map(node => {
          // Change on child row
          if (node._id === item._id) {
            return _.set(node, name, value);
          }

          return node;
        });

        return { ...row, nodes: newNodes };
      }

      return row;
    });

    setTableRows(newRows);
  };

  const valideteRow = ({ parent, settings }) => {
    if (settings.isAutoCosts && settings.autoCostsValue === null) return false;

    if (settings.isAutoCosts && !settings.autoCostsSumItems.length && !parent) return false;

    return true;
  }

  const highlightRow = (key, isHighlighted = false) => {
    const el = document.querySelector(`.estimate-summary-settings-table-container .row-key-${key}`);

    setElementHighlight(el, isHighlighted);

    if (isHighlighted) {
      setTimeout(() => {
        setElementHighlight();
      }
        , 2000);
    }
  }

  const handleSubmit = async () => {
    setElementHighlight();

    const message = t('Užpildykite visus privalomus laukus');

    // Validate if all required fields are filled
    for (const row of tableRows) {
      if (!valideteRow(row)) {
        highlightRow(row._id, true);
        toast(message, 'error');
        return;
      }

      if (!row.nodes.length) continue;

      for (const node of row.nodes) {
        if (!valideteRow(node)) {
          highlightRow(node._id, true);
          toast(message, 'error');
          return;
        }
      }
    }

    await submitChange();

    handleClose();
  };

  const handleReset = async () => {
    setElementHighlight();

    if (!window.confirm(t('Ar tikrai norite išvalyti nustatymus?'))) return;

    try {
      await api.delete(`/estimate-summaries/${summaryId}/settings`);

      toast(t('Nustatymai išvalyti'), 'success');
    } catch (err) {
      console.error(err);

      toast(t('Nepavyko išvalyti nustatymų'), 'error');
    }

    handleClose(true);
  };

  const renderVat = ({ value, item }) => item.context !== ITEM_CONTEXTS.CONSTRUCTION ? (
    <input
      type="number"
      defaultValue={value}
      style={{
        border: '1px solid #333333',
        padding: '0px 2px',
        width: '100%',
      }}
      onChange={e => handleChange({ name: 'settings.vatPercentage', value: e.target.value, item })}
    />
  ) : null;

  const renderAssignedCosts = ({ item, value }) => !!item.isEditable ? (
    <>
      <input
        type="checkbox"
        checked={!!item?.settings?.isAutoCosts}
        style={{
          display: 'inline-block',
          marginRight: '5px',
          verticalAlign: 'middle',
        }}
        onChange={e => handleChange({ name: 'settings.isAutoCosts', value: e.target.checked, item })}
        disabled={item.parent && !tableRows.find(f => f._id === item.parent)?.settings?.isAutoCosts}
      />

      <input
        disabled={!item?.settings?.isAutoCosts}
        type="number"
        defaultValue={value}
        style={{
          display: 'inline-block',
          width: '70px',
          marginRight: '5px',
          border: '1px solid #333333',
          padding: '0px 2px',
        }}
        onChange={e => handleChange({ name: 'settings.autoCostsValue', value: e.target.value, item })}
      />
    </>
  ) : (item.context !== ITEM_CONTEXTS.CONSTRUCTION ? value : null);

  const renderAssignedCostsSumItems = ({ item, value = [] }) => {
    return !!item.isEditable && !item.parent ? (
      <Field
        disabled={!item?.settings?.isAutoCosts || item.parent}
        type="select"
        multiple
        value={value}
        options={flatStructure.filter(f => f.value !== item._id)}
        onChange={({ value }) => handleChange({ name: 'settings.autoCostsSumItems', value, item })}
      />
    ) : (item.context !== ITEM_CONTEXTS.CONSTRUCTION ? value?.map((val) => flatStructure.find(f => f.value === val)?.label)?.join(', ') : null);
  }

  const tableColumns = [
    { key: 'code', readonly: true, label: t('Skyrius'), columnConfig: { stiff: true }, cellConfig: { style: { minHeight: '40px' } } },
    { key: 'title', readonly: true, label: t('Suvestinės sąmatos skyriaus pavadinimas') },
    { key: 'settings.vatPercentage', label: `${t('PVM')}, %`, render: renderVat },
    { key: 'settings.autoCostsValue', label: `${t('Išlaidų rodiklis')}, %`, render: renderAssignedCosts },
    { key: 'settings.autoCostsSumItems', label: t('Išlaidas skaičiuoti nuo'), render: renderAssignedCostsSumItems },
  ];

  return (
    <CommonDialog
      visible
      onClose={handleClose}
      showClose
      maxWidth="lg"
      style={{ position: 'relative' }}
      title={t('Nustatymai')}
      actions={[
        <div key="action-buttons" style={{ textAlign: 'right', width: '100%' }}>

          <Button
            color="primary"
            variant="outlined"
            onClick={handleReset}
            style={{ marginRight: '10px' }}
          >
            {t("Išvalyti nustatymų rodiklius")}
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            size="small"
          >
            {t("Perskaičiuoti")}
          </Button>

        </div>
      ]}
    >
      <div className="estimate-summary-settings-table-container">
        <TreeDataTableV2
          gridTemplateColumns={'12% 44% 5% 10% 29%'}
          height="400px"
          rows={tableRows}
          columns={tableColumns}
          expandedRowKeys={tableRows.map(({ _id }) => _id)}
          isVirtualized={false}
        />
      </div>
    </CommonDialog>
  );
}

export default EstimateSummarySettingsModal;